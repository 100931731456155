import React from 'react';
import StoreUtil from './util';
import configData from './config.json';
import { IoIosTrash, IoIosCart, IoMdPerson } from 'react-icons/io'
import { Menu, MenuSurfaceAnchor, MenuItem } from '@rmwc/menu'
import { ListDivider } from '@rmwc/list'
import '@rmwc/menu/styles'


class CartItem extends React.Component {
    constructor(props)
    {
        super(props)
        this.globs = props.globs;
        this.updated = false;
        this.state = {
            qty: this.props.item.Quantity
        }
    }


    //
    // reset when cart reloaded
    componentDidUpdate () {
        if (this.updated && this.props.item.Quantity !== this.state.qty) {
            this.updated = false
            this.setState (
                { qty: this.props.item.Quantity }
            )
        }
    }


    trash(item) {
        // set new quantity
        this.globs.addToCart(item.ProductID,-item.Quantity,false)
    }

    update(e,item) {
        const val = e.target.value < 0 ? 0 : parseInt(e.target.value);
        this.setState( {qty: val})
    }

    blur(e, item) {
        // set new quantity
        if (parseInt(e.target.value) !== item.Quantity)
            this.globs.addToCart(item.ProductID,e.target.value,false)
            .then ( () => {
                this.setState (
                    { qty: this.props.item.Quantity }
                )
            })
    }

    render() {
        const quantity = (this.props.edit) ? (
            <input className="qtyinput" type="number" 
                value={this.state.qty} 
                onBlur={(e) => this.blur(e, this.props.item)}
                onChange={(e) => this.update(e,this.props.item)} />
        ) : this.props.item.Quantity;

        const price = this.props.item.FullPrice > this.props.item.Price ?
            (
                <div>
                <span className="strike">{StoreUtil.formatCurrency(this.props.item.FullPrice)}</span><br></br>
                {StoreUtil.formatCurrency(this.props.item.Price)}
                </div>
            ) : 
            (
                <div>{StoreUtil.formatCurrency(this.props.item.Price)}</div>
            )

        const trash = (this.props.edit) ? (<span className="trash" onClick={() => this.trash(this.props.item)}><IoIosTrash size={"2em"} /></span>) : false;
        return (
            <li className="cartitem">
                <span>{quantity}</span>
                <span>{this.props.item.Name}</span>
                <span className="moneycolumn">{StoreUtil.formatCurrency(this.props.item.ItemPrice)}ea</span>
                <span className="moneycolumn">{price}</span>
                {trash}
            </li>
        )
    }
}


function CartCoupons(props) {
    if (configData.Coupons === false) return false;
    const apply = (!!props.value) ?
        <button className="small" onClick={() => props.onApply()}>Apply</button> : ''
    return (<div className="couponSection">
        <span>Enter Coupon </span>
        <input name="couponinput" className="couponinput"
            value={props.value}
            placeholder={"Code"}
            onKeyDown={(e) => props.onKeyDown(e)}
            onChange={(e) => props.onChange(e)}></input>
        {apply}
    </div>)
}

function CartPoints(props) {
    if (!props.points || configData.LoyaltyPoints===false) return false;
    const apply = (!!props.value) ?
        <button className="small" onClick={() => props.onApply()}>Apply</button> : ''
    return (
        <div className="pointsSection">
            <span>{configData.POINTSNAME} {props.points.toLocaleString()} </span>
            <input name="pointsinput" className="pointinput" 
                value={props.value}
                placeholder={"Use Points"}
                onKeyDown={(e) => props.onKeyDown(e)}
                onChange={(e) => props.onChange(e)}></input>
            {apply}
        </div>
    )
}


function PointsRow(props) {
    if (!props.pointsused) return false;
    return (
        <tr>
        <td>{configData.POINTSNAME}</td>
        <td className="moneycolumn">{(-props.pointsused).toLocaleString()}</td>
        </tr>
    )
}

function DiscountsRow(props) {
    if (!props.cart.Discounts) return false;
    let title = "";
    if (props.cart.Coupons != null && props.cart.Coupons.length > 0)
    {
        if (props.cart.PointsUsed > 0)
            title = "Coupons & " + configData.POINTSNAME
        else
            title = "Coupons"
    }
    else {
        title = configData.POINTSNAME + " Applied"
    }
    return (
        <tr><td>{title}</td><td className="moneycolumn">{StoreUtil.formatCurrency(props.cart.Discounts)}</td></tr>        
    )
}



function UserMenu(props) {
    const [open, setOpen] = React.useState(false);

    const user = (props.user && props.user.indexOf('@') > 0) ? props.user.split('@')[0] : null

    function select(evt) {
        if (evt.detail.index === 0) {
            props.globs.loadForm("user")
        }
        else if (evt.detail.index === 1) {
            props.globs.loadForm("orders")
        }
        else if (evt.detail.index === 2) {
            props.globs.logOut()
        }
        setOpen(false)
    }

    if (user) {
        return (
            <MenuSurfaceAnchor>
                <Menu open={open}
                    anchorCorner={'bottomLeft'}
                    onSelect={evt => select(evt)}
                    onClose={evt => setOpen(false)}
                >
                    <MenuItem>Account</MenuItem>
                    <MenuItem>Orders</MenuItem>
                    <ListDivider />
                    <MenuItem>Log Out</MenuItem>
                </Menu>
                <span className="userlink button" onClick={evt => setOpen(!open)}><IoMdPerson /> {user}</span>
            </MenuSurfaceAnchor>
        )
    }
    else {
        return <span className="userlink button" onClick={() => props.globs.loadForm('login')}><IoMdPerson /> Log In</span>
    }
}

///
// define component to always access products and 
// link to cart.
export function CartHeader(props) {
    const cart = props.cart;
    const count = (cart && cart.Count) ? cart.Count+" items" : ""
    const total = (cart && cart.Total)
    return ( 
        <div className="cartnav">
            <span className="custommenu" dangerouslySetInnerHTML={{ __html: props.custommenu }}></span>
            <span className="cartmenu">
            <span className="cartlink button">
                <span onClick={() => props.globs.loadForm("cart", true)} className="flexcontainer"><IoIosCart size={"1em"}/><span className="items">{count}</span>
                    <span className="total">&nbsp;{StoreUtil.formatCurrency(total)}</span>
                </span>
            </span>
            <UserMenu user={props.user} globs={props.globs} />
            </span>
        </div>
    )
}


export function CartSummary(props) {
    const cart = props.cart;
    const tax = (cart.Taxes === null) ? "TBD" : StoreUtil.formatCurrency(cart.Taxes);
    const shipping = cart.Items.filter(it => it.IsShipping).reduce((a, it) => a + it.Price, 0)
    const shiptype = cart.Items.filter(it => it.IsShipping).map(it => it.Name).join(',');
    const shippingrow = (cart.ShipAddress || shipping !== 0) ? <tr><td>{shiptype}</td><td className="moneycolumn">{StoreUtil.formatCurrency(shipping)}</td></tr> : false;
    const paid = cart.Total - cart.Balance;
    const paidrow = (paid > 0) ? <tr><td>Paid</td><td className="moneycolumn">{StoreUtil.formatCurrency(-paid)}</td></tr> : false;

    return (
        <div className="storesummary">
            <table className="cartsummary">
                <tbody>
                    <tr><td>Items</td><td className="moneycolumn">{cart.Count}</td></tr>
                    <tr><td>Subtotal</td><td className="moneycolumn">{StoreUtil.formatCurrency(cart.Subtotal-shipping)}</td></tr>
                    <PointsRow pointsused={cart.PointsUsed} />
                    <DiscountsRow cart={cart} />
                    {shippingrow}
                    <tr><td>Tax</td><td className="moneycolumn">{tax}</td></tr>
                    {paidrow}
                    <tr><td>Due</td><td className="moneycolumn">{StoreUtil.formatCurrency(cart.Balance)}</td></tr>
                </tbody>
            </table>
        </div>
    )
}


export default class Cart extends React.Component {
    constructor(props)
    {
        super(props)
        this.globs = props.globs;
        this.state = {
            error: "",
            couponinput: "",
            pointsinput: (this.props.cart?.PointsUsed) || ''
        }
    }

    onCheckout() {
        if (!this.props.username || this.props.username === "QUICK")
            this.globs.loadForm("login:cart")
        else if ((configData.Shipping || configData.PickUp) &&
            !(this.props.cart && this.props.cart.Digital))
            this.globs.loadForm("shipping")
        else {
            // straight to payment, no shipping
            // but we must submit a "pickup" address so
            // sales tax is calculated.
            this.globs.shipAddress({})
                .then((options) => {
                    this.globs.loadForm("payment", true)
                });
        }
    }

    addcoupon(value) {
        const me = this;
        // submit the coupon
        this.globs.addCoupon(value)
            .then(ok => {
                if (ok) me.setState({ couponinput: "" })
            })
    }

    addpoints(value) {
        // submit the points            
        this.globs.usePoints(value)
            .then(ok => {
                console.log("applied points", ok)
            })
    }

    keydown(e) {
        var key = e.which || e.keyCode;
        if (key === 13) {
            if (e.target.name === "couponinput")
            {
                this.addcoupon(e.target.value)
            }
            else if (e.target.name === "pointsinput")
            {
                this.addpoints(e.target.value)
            }
        }
    }

    change(e) {
        let newstate = {}
        newstate[e.target.name] = e.target.value;
        this.setState(newstate)
    }

    static lineText(text) {
        return (text) ? StoreUtil.escapeHTML(text) + "<br>" : "";
    }

    deliveryInfo(cart) {
        if (cart.ShipAddress) {
            return "<span>Delivery To:<br>" +
                Cart.lineText(cart.ShipAddress.RecipientName) + 
                Cart.lineText(cart.ShipAddress.BusinessName) + 
                Cart.lineText(cart.ShipAddress.Street) + 
                Cart.lineText(cart.ShipAddress.StreetLine2) +
                Cart.lineText(cart.ShipAddress.City + ", " + cart.ShipAddress.State + " " + cart.ShipAddress.PostalCode) +
                Cart.lineText(cart.ShipAddress.Country) +"</span><br>"
        }
        return '';
    }

    

    render() {
        const browsebutton = !this.props.edit ? false :
            <button onClick={() => this.globs.loadForm("browse")}>Continue Shopping</button> 


        if (!this.props.cart)
            return browsebutton;

        const cart = this.props.cart;

        // don't allow checkout without any items.
        const checkoutbutton = (!this.props.edit || cart.Count < 1) ? false :
            <button className="checkout" onClick={() => this.onCheckout()}>Check Out</button>

        const items = cart.Items.filter(it => !it.IsShipping).map( (it) => 
            <CartItem key={it.ProductID} globs={this.globs} item={it} edit={this.props.edit && !it.IsShipping}/>
        )
        const coupons = cart.Coupons && cart.Coupons.map((it) =>
            <span className="couponcode" title={ it.Description }>{it.Code} </span>
        )

        const delivery = this.props.edit ? this.deliveryInfo(cart) : '';

        return (
            <div className="storecart">
                <div className="cartitems">
                <ul className="cartitems">{items}</ul>
                </div>
                <div>
                    {coupons}
                </div>
                { this.props.edit && 
                <>
                    <CartCoupons
                        value={this.state.couponinput}
                        onKeyDown={(e) => this.keydown(e)}
                        onApply={() => this.addcoupon(this.state.couponinput)}
                        onChange={(e) => this.change(e)}
                    />
                    <CartPoints points ={cart.PointsBalance} 
                        pointsused={cart.PointsUsed} 
                        value={this.state.pointsinput}
                        onApply={() => this.addpoints(this.state.pointsinput)}
                        onKeyDown={(e) => this.keydown(e)}
                        onChange={(e) => this.change(e)}
                        />
                </>
                }
                <CartSummary cart={cart} />
                <span className="errortext">{this.props.error}</span>
                <span className="deliverinfo" dangerouslySetInnerHTML={{ __html: delivery }}></span>
                <div className="buttoncenter">
                {checkoutbutton}
                {browsebutton}
                </div>
            </div>
        )
    }
}

