import React from 'react';



//
// put the recaptcha policy and ToS link into appropriate forms.
//
export default function RecaptchaBadge(props) {

    return (
        <span className="policytext">
            protected by reCAPTCHA and the Google&nbsp;
            <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> and&nbsp;
            <a href="https://policies.google.com/terms" rel="noopener noreferrer" target="_blank">Terms of Service</a> apply.
        </span>
    )
}