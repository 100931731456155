import React from 'react';
import StoreUtil from './util';
import configData from './config.json';


function StoreChoice(props) {
    return (
        <div onClick={props.onClick} className={"storechoice"}>
            <span className="storename">{props.store.Name}<br></br></span>
            <span className="storeaddress">{props.store.Address}</span>
        </div>
    )
}

//
// Our login/register form
//
export default function ChooseStore(props) {

    const stores = configData.Stores.map((store) =>
        <StoreChoice key={store.ORGID} store={store}
            onClick={() => props.globs.chooseStore(store).then(() => props.globs.loadForm("browse"))} />
    )
    const instruct = StoreUtil.toHtml(configData.StoreChoiceMessage ?? "Choose Location");
    return (
        <>
        <span className="chooselocationtxt" dangerouslySetInnerHTML={{ __html: instruct }}></span>
        { stores}
        </>
    )
}