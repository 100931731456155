import React from 'react';
import StoreUtil from './util.js'
import {TextField} from '@rmwc/textfield'



//
// Our login/register form
//
export default class UserForm extends React.Component {

    constructor(props) {
        super(props)
        this.globs = this.props.globs
        if (this.props.customer) {
            this.state = {
                email: this.props.customer.email || '',
                oldpassword: "",
                newpassword: "",
                updatephone: StoreUtil.formatPhone(this.props.customer.phone),
                firstname: this.props.customer.firstname || '',
                lastname: this.props.customer.lastname || '',
                pwstrength: 6,
                lastclick: "",
                savephone: this.props.customer.phone
            }
        }
    }

    componentDidUpdate() {
        // update if our customer changed. We don't get
        // phone # and some other things until later.
        if (this.props.customer &&
            this.state.savephone !== this.props.customer.phone) {
            this.setState({
                savephone: this.props.customer.phone,
                updatephone: StoreUtil.formatPhone(this.props.customer.phone),
                firstname: this.props.customer.firstname || this.state.firstname,
                lastname: this.props.customer.lastname || this.state.lastname,
                email: this.props.customer.email || this.state.email,
                oldpassword: "",
                newpassword: ""
            })
        }
    }

    changeInput(e) {
        let newstate = { error: null }
        newstate[e.target.name] = e.target.value
        this.setState ( newstate )
    }

    blurPhone(e) {
        var ph = e.target.value;
        if (StoreUtil.validPhone(ph)) {
            ph = StoreUtil.formatPhone(ph)
            this.setState({
                updatephone: ph
            })
        }
    }

    clickUpdate() {
        this.setState( {
            lastclick: "update"
        })

        this.globs.updatecustomer( {
            Email: this.state.email,
            Phone: this.state.updatephone,
            FirstName: this.state.firstname,
            LastName: this.state.lastname,
            Password: this.state.newpassword,
            Oldpassword: this.state.oldpassword
        })
        .then( (ok) => {
            if (ok===true) {
                return this.globs.openCart()
                .then( (cartid) => {
                  this.globs.loadForm(this.props.nextscreen || (cartid ? "cart" : "browse") )
                })
            }
        })
    }


    //
    // access the updated customer information when
    // mounting
    componentDidMount() {
    }

 
    blurPassword(e) {
        const score = StoreUtil.scorePassword(e.target.value)
        this.setState( {
            pwstrength: score
        })
    }

    focusPassword(e) {
        // clear message while typing password
        this.setState( {
            pwstrength: 6
        })
    }     

    render() {
        let updatemessage = "";
        if (!this.props.customer) return false;

        const phonevalid = (this.state.updatephone.length === 0 || StoreUtil.validPhone(this.state.updatephone))

        if (this.state.newpassword &&
            this.state.newpassword.length > 0 &&
            this.state.pwstrength < 4) {
            updatemessage = "Password too weak. Use 8+ characters, mix case, numbers and symbols"
        }
        else if (this.state.lastclick === "update" &&
            this.props.error) {
            updatemessage = this.props.error;
        }
        else if (!StoreUtil.validEmail(this.state.email)) {
            updatemessage = "Invalid E-mail"
        }
        else if (!phonevalid) {
            updatemessage = "Invalid Phone"
        }


        const canUpdate = 
                StoreUtil.validEmail(this.state.email) 
            && (!this.state.newpassword || (this.state.pwstrength >= 4 && this.state.oldpassword.length > 5 )) 
            && this.state.firstname.length > 0 
            && this.state.lastname.length > 0
            && phonevalid
                

        return (
            <div className="storeuser">
            <fieldset>
            <legend>Update Customer Information</legend>
            <TextField outlined 
                    placeholder="First Name" 
                    value={this.state.firstname}
                    onChange={(e) => this.changeInput(e)} 
                    label="First Name"
                    name="firstname"  /><br></br>
            <TextField outlined
                    value={this.state.lastname}
                    placeholder="Last Name" 
                    label="Last Name"
                    onChange={(e) => this.changeInput(e)} 
                    name="lastname"  /><br></br>
            <TextField outlined 
                    placeholder={"Phone Number"} 
                    pattern="(\+\d[\d ]{3,20})|(((\(\d{3}\))|(\d{3}))\s?-?\s?\d{3}\s?-?\s?\d{4})"
                        value={this.state.updatephone}
                        type={"phone"}
                    onChange={(e) => this.changeInput(e)} 
                    onBlur={(e) => this.blurPhone(e)}
                    label="Phone"
                    name="updatephone"  /><br></br>
            <TextField outlined required
                    placeholder={"Email Address"} 
                        value={this.state.email}
                        type={"email"}
                    onChange={(e) => this.changeInput(e)} 
                    label="Email"
                    name="email"  /><br></br>
            <TextField outlined
                    onBlur={(e) => this.blurPassword(e)} 
                    onFocus={(e) => this.focusPassword(e)} 
                    onChange={(e) => this.changeInput(e)} 
                    name="newpassword" 
                    type="password"
                    autoComplete="new-password"
                    label="New Password"
                    placeholder="New Password" /><br></br>
            <TextField outlined
                    onChange={(e) => this.changeInput(e)} 
                    name="oldpassword" 
                    type="password" 
                    autoComplete="current-password"
                    label="Old Password"
                    placeholder="Old Password" /> <br></br>
            <span className="errortext">{updatemessage}</span><br></br>
            <button disabled={!canUpdate} onClick={() => this.clickUpdate()}>Update</button>
            </fieldset>
            <div className="flexLinks">
            <span className="clickable" 
                onClick={() => this.globs.loadForm("link")} >
                Link In-Store Account
            </span>   
            </div>         

            </div>
        )
    }

}