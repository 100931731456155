import React, { Suspense } from 'react';
import configData from './config.json';
import StoreUtil from './util.js'
import ProductBrowser from './browser.js'
import ChooseStore from './choosestore.js'
import Cart from './cart.js'
import { CartHeader } from './cart.js'
import ShippingForm from './shipping.js'
import LoginForm from './login.js'
import UserForm from './user.js'
import ForgotPassword from './forgot.js'
import LinkCustomer from './linkcustomer.js'
import RecaptchaBadge from './recaptchabadge.js'
import Orders from './orders.js'
import { createSnackbarQueue, SnackbarQueue } from '@rmwc/snackbar';
import { IoIosCheckmarkCircle, IoIosWarning } from 'react-icons/io'

// paymentform is rather heavy, as it includes the CreditCardInput
const PaymentForm = React.lazy(() => import('./payment.js'))


export default class Store extends React.Component {
    constructor(props) {
        super(props)
        //
        // get settings from static HTML page (or PHP page)
        //
        Object.assign(configData, window.StoreConfig)

        //
        // get cart information from cookies
        //
        let saved = localStorage.getItem('grindless_store')
        if (saved) saved = JSON.parse(saved)
        if (!saved) saved = {}

        this.state = {
            customer: {
                id: saved.CustomerID || "0",
                user: saved.Username || "QUICK",
                token: saved.CustomerToken || ""
            },
            cartid: saved.CartID || null,
            cart: null,
            screen: "browse",
            nextscreen: "cart",
            custommenu: configData.CustomMenuHTML || '',
            customlinks: configData.CustomLinksHTML || '',
            store: saved.store || null,
            error: null
        }

        this.globs = {
            chooseStore: (s) => this.loadStore(s),
            loadForm: (f, r) => this.loadForm(f, r),
            openCart: (id) => this.openCart(id),
            addToCart: (p, q, add) => this.addToCart(p, q, add),
            usePoints: (p) => this.usePoints(p),
            addCoupon: (c) => this.addCoupon(c),
            pay: (n, cc, e, cv, z) => this.pay(n, cc, e, cv, z),
            login: (u, pw) => this.login(u, pw),
            logOut: () => this.logOut(),
            updatecustomer: (i) => this.updatecustomer(i),
            linkcustomer: (i) => this.linkcustomer(i),
            forgotpass: (i) => this.forgotpass(i),
            register: (fn, ln, e, ph, pw) => this.register(fn, ln, e, ph, pw),
            shipAddress: (i) => this.shipAddress(i),
            shipOption: (i) => this.shipOption(i),
            getAddresses: () => this.getAddresses(),
            refresh: () => this.readCustomer(),
            recaptcha: (action) => this.recaptcha(action),
            queue: createSnackbarQueue(),
            submitting: false
        }
    }


    useQuery() {
        return new URLSearchParams(window.location.search);
    }


    componentDidUpdate(prevProps, prevState) {
        // console.log("store update",prevProps)
    }



    loadCustomHtml() {
        var custom = configData.CustomMenuURL
        if (custom) {
            if (custom.startsWith('//'))
                custom = window.location.protocol + custom;

            // don't run without https, since we are ecommerce and depend on encryption
            if (custom.startsWith('https://')) {
                StoreUtil.loadHTML(custom)
                    .then((html) => {
                        this.setState({ custommenu: html })
                    });
            }
        }

        custom = configData.CustomLinksURL
        if (custom) {
            if (custom.startsWith('//'))
                custom = window.location.protocol + custom;

            // don't run without https, since we are ecommerce and depend on encryption
            if (custom.startsWith('https://')) {
                StoreUtil.loadHTML(custom)
                    .then((html) => {
                        this.setState({ customlinks: html })
                    });
            }
        }
    }


    //
    // log in using the Username and Password saved in state.
    loginCustomer() {
        const me = this;
        if (!this.state.customer.token) {
            // no token, get it
            return me.login("QUICK", "")
        }
        else {
            // return it as a promise 
            return new Promise((resolve, reject) => {
                resolve(true)
            })
        }
    }

    //
    // save state to localstorage
    saveCart() {
        //console.log("saving ", this.state)
        localStorage.setItem('grindless_store',
            JSON.stringify({
                CustomerID: this.state.customer.id,
                Username: this.state.customer.user,
                CustomerToken: this.state.customer.token,
                CartID: this.state.cartid,
                store: this.state.store
            }))
    }

    //
    // when setting cart or customer, use this method to save
    setStateSave(state) {
        return new Promise((resolve) =>
            this.setState(state, () => {
                this.saveCart()
                resolve(this.state.cart);
            })
        )
    }


    getAddresses() {
        return StoreUtil.callApi(
            '/addresses/' +
            configData.ORGID +
            '/' + this.state.customer.id +
            '/' + this.state.customer.token,
            { method: "GET" }
        ).then(response => {
            if (response.ok) {
                return response.json()
            }
            else {
                this.globs.queue.notify({
                    title: "Error getting addresses",
                    icon: <IoIosWarning size={30} />
                });
                return null;
            }
        }).catch(reason => {
            this.globs.queue.notify({
                title: "Error getting addresses",
                icon: <IoIosWarning size={30} />
            });
        })
    }


    loadCart(cartid) {
        return StoreUtil.callApi(
            '/cart/' +
            configData.ORGID +
            '/' + cartid +
            '/' + this.state.customer.id +
            '/' + this.state.customer.token,
            { method: "GET" }
        ).then(response => {
            if (response.ok) {
                return response.json()
            }
            else {
                this.globs.queue.notify({
                    title: "Unable to open cart",
                    icon: <IoIosWarning size={30} />
                });
                return null;
            }
        }).catch(reason => {
            this.globs.queue.notify({
                title: "Error opening cart",
                icon: <IoIosWarning size={30} />
            });})
    }


    //
    // get details from server for a cart we already have in progress
    openExistingCart(cartid) {
        const me = this;

        if (!cartid) cartid = this.state.cartid;
        if (!cartid) return new Promise((resolve, reject) => {
                resolve(null)
            });

        return this.loadCart(cartid)
            .then((cart) => {

                if (cart && cart.Message) {
                    console.log("got cart message: " + cart.Message)
                    // this is usually that an item went out of stock.
                    // show message but everything else the same.
                    this.globs.queue.notify({
                        title: cart.Message,
                        icon: <IoIosWarning size={30} />
                    });
                }

                if (cart && cart.Status === "Open") {
                    return me.setStateSave({
                        cartid: cart.CartID,
                        cart: cart
                    }).then(() => cart)
                }
                else if (cart) {
                    // return the cart, reset state only  if
                    // we have the same cart already open
                    if (cart.CartID === me.state.cartid)
                        return me.setStateSave({
                            cartid: null,
                            cart: null
                        }).then(() => cart)
                    else
                        return cart;
                }
                else {
                    // failure. clear state, return null
                    return me.setStateSave({
                        cartid: null,
                        cart: null
                    })
                }
            })
    }


    setError(msg) {
        //
        // check for string encoded JSON
        if (typeof msg === 'string' && msg.startsWith("{")) {
            try {
                // try to convert from json to an object
                msg = JSON.parse(msg)
            } catch(e)
            {
                console.log(e)
            }
        }

        if (msg != null && typeof msg === 'object') {
            if (msg.Message)
                msg = msg.Message;
            else
                msg = msg.toString();
        }

        if (msg) {
            this.globs.queue.notify({
                title: msg,
                icon: <IoIosWarning size={30} />
            });
        }


        return this.setState(
            {
                error: msg
            }
        )
    }

    //
    // if we get an unauthorized error, we are not logged in so erase
    // our state.
    invalidateLogin() {
        var newState = {
            customer: {
                user: "QUICK",
                token: null
            },
            cart: null,
            cartid: null

        };

        return new Promise((resolve) =>
            this.setState(newState, resolve)
        )
    }

    //
    // create a new empty cart
    openNewCart() {
        const me = this;

        return StoreUtil.callApi('/cart', {
            method: "PUT",
            params: {
                OrgID: configData.ORGID,
                CustomerID: this.state.customer.id,
                CustomerToken: this.state.customer.token
            }
        }).then(response => {
            if (response.ok)
                return response.json();
            else if (me.state.customer.token) {
                // must have invalid login.
                return me.invalidateLogin()
                    .then(() => me.loginCustomer())  // get default customer 
                    .then(() => me.openNewCart())
            }
            else {
                me.setError(response.error)
                return null
            }
        })
    }

    //
    // open given cart. if nont open current cart, if none open new cart.
    // 
    openCart(cartid) {
        const me = this;
        const openNew = !cartid;

        return this.loginCustomer().then((ok) => {
            if (!ok) return null;
            if (!cartid) cartid = me.state.cartid;
            if (me.state.cart != null && me.state.cart.CartID === cartid) {
                return me.state.cart; // already loaded.
            }
            //
            // attempt to open existing cart
            //
            return me.openExistingCart(cartid)
        }).then((cart) => {
            // 
            // if we didn't get a cartid
            // CREATE A NEW CART
            //
            if (openNew && !me.state.cartid) {
                return me.openNewCart().then(j => {
                    me.setStateSave({
                        cartid: j && j.CartID,
                        cart: j
                    })
                    return j
                })
            }
            else {
                // just return loaded cart
                return cart;
            }
        })
    }


    addCoupon(coupon) {
        const me = this;
        return StoreUtil.callApi('/coupon', {
            method: "PUT",
            params: {
                OrgID: configData.ORGID,
                CustomerID: this.state.customer.id,
                CustomerToken: this.state.customer.token,
                CartID: this.state.cartid,
                CouponCode: coupon
            }
        })
            .then(response => {
                if (response.ok)
                    // open cart after success
                    return me.openExistingCart()
                        .then(() => true)
                else {
                    return response.text().then(err => {
                        me.setError(err)
                        return false
                    })
                }
            })
            .catch(reason => {
                me.setError("Network Error")
                return false
            })
    }

    //
    // This can be called from descendants to add items or change their 
    // quantity
    //
    addToCart(product, quantity, add) {
        let productID = product;
        if (typeof product === 'object')
            productID = product.ID;

        const me = this;
        me.setError(null)
        return this.openCart().then(() => {
            //
            // change quantity if our intention is to add quantity to the existing quantity
            // add is false if editing cart.
            if (add && this.state.cart) {
                const item = this.state.cart.Items.find(it => it.ProductID === productID)
                if (item) quantity = quantity * 1 + item.Quantity * 1
            }

            return StoreUtil.callApi('/item', {
                method: quantity === 0 ? "DELETE" : "POST",
                params: {
                    OrgID: configData.ORGID,
                    CustomerID: me.state.customer.id,
                    CustomerToken: me.state.customer.token,
                    CartID: me.state.cartid,
                    ProductID: productID,
                    Quantity: quantity
                }
            })
        }).then(response => response.json()
        ).then(j => {
            if (j === "Maximum Reached") {
                me.setError("Maximum quantity reached")
            }
            if (j === "success" || j === "Maximum Reached") {

                this.globs.queue.notify({
                    title: j,
                    icon: <IoIosCheckmarkCircle size={30} />
                });
                // reload the cart
                return me.openExistingCart()
            }
        }).catch(error => console.log(error))
            ;
    }


    doIt(formname) {
        const history = window.history;

        let newstate = {
            screen: formname,
            nextscreen: null,
            error: ''
        };
        if (formname.includes(":")) {
            const parts = formname.split(":");
            newstate.screen = parts[0]
            newstate.nextscreen = parts[1] || null
        }
        if (formname === "payment") {
            // save payment cart so after it is paid we can load
            // new cart but still give it information about the
            // order that was just paid (ie. receipt info)
            newstate.paycart = this.state.cart;
        }
        // update URL 
        const newpage = window.location.origin + window.location.pathname +
            StoreUtil.makeRoute(newstate.screen);
        history.pushState(null,null,newpage)
        this.setState(newstate)
    }

    //switch screens
    loadForm(formname, reload) {
        if (reload) {
            this.openExistingCart()
                .then(j => this.doIt(formname))
        }
        else {
            this.doIt(formname);
        }
        
    }


    logOut() {
        const me = this;
        // remove online key
        StoreUtil.callApi("/customer/logout", {
            method: "POST",
            params: {
                OrgID: configData.ORGID,
                CustomerID: this.state.customer.id,
                CustomerToken: this.state.customer.token
            }
        })
        return this.invalidateLogin()
            .then(() => {
                // save state when logging out to prevent 
                // future attempts with the invalidated id/token
                me.saveCart()
                me.loadForm("browse")
            })
    }

    login(username, password, token) {
        const me = this

        me.setError(null)
        return StoreUtil.callApi("/customer/login", {
            method: "POST",
            params: {
                OrgID: configData.ORGID,
                CustomerID: this.state.customer.id,
                CustomerToken: this.state.customer.token,
                CartID: this.state.cartid,  // move to new customer
                Username: username,
                Password: password,
                CaptchaV3: token
            }
        })
        .then(response => {
            if (response.ok)
                return response.json()
                    .then(j => me.updateFromResponse(j))
                    .then(() => me.openExistingCart())
                    .then(() => true)
            else {
                return response.text().then(err => {
                    if (response.status === 404 || response.status === 412)
                        err = "Incorrect Email or Password"
                    if (err.startsWith('{')) {
                        err = JSON.parse(err).Message || err
                    }
                    me.setError(err)
                    return false
                })
            }
        }, result => me.setError(result))
    }


    //
    // validate the customer and get customer information and
    // current cart. Only works if our login is still valid.
    //
    readCustomer() {
        const me = this;

        me.setError(null)
        return StoreUtil.callApi("/customer/{orgid}/" + this.state.customer.id + "/" + this.state.customer.token,
            {
                method: "GET"
            }
        )
        .then(response => {
            if (response.ok) {
                return response.json()
                    .then(j => me.updateFromResponse(j))
                    .then(() => me.openExistingCart())
                    .then(() => true)
            }
            else {
                return response.text()
                    .then(err => {
                        if (response.status === 404)
                            err = "Incorrect Email or Password"
                        me.setError(err)
                        return false
                    })
                    .then(() => this.invalidateLogin())
                    .then(() => false)
            }
        });
    }


    //
    // receiving successful response, make sure
    // state is set and saved and then resolve as TRUE
    //
    updateFromResponse(j) {
        return new Promise((resolve) =>
            this.setState({
                customer: {
                    id: j.CustomerID,
                    token: j.CustomerToken,
                    user: j.Email,
                    email: j.Email,
                    phone: j.Phone,
                    firstname: j.FirstName,
                    lastname: j.LastName
                },
                cartid: j.CartID,
                cart: j.CartID === this.state.cartid ? this.state.cart : null
            }, () => {
                this.saveCart()
                resolve(true)
            })
        );
    }



    shipOption(id) {
        let args = {
            OrgID: configData.ORGID,
            CustomerID: this.state.customer.id,
            CustomerToken: this.state.customer.token,
            CartID: this.state.cartid,
            ProductID: id,
            Quantity: 1  /* unused */
        }

        this.setError(null)

        return StoreUtil.callApi("/cart/ship", {
            method: "POST",
            params: args
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => {
                            this.globs.queue.notify({
                                title: "Selected Shipping",
                                icon: <IoIosCheckmarkCircle size={30} />
                            });
                            return true;
                        });
                }
                else {
                    return response.json()
                        .then(j => {
                            this.setError(j)
                            return false;
                        })
                }
            })

    }



    shipAddress(info) {
        let args = {
            OrgID: configData.ORGID,
            CustomerID: this.state.customer.id,
            CustomerToken: this.state.customer.token,
            CartID: this.state.cartid
        }

        Object.assign(args, info)
        this.setError(null)


        return StoreUtil.callApi("/cart/address", {
            method: "POST",
            params: args
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => {
                            this.globs.queue.notify({
                                title: args.Address ? "Shipping Address Set" : "Pick Up Chosen",
                                icon: <IoIosCheckmarkCircle size={30} />
                            });
                            return j;
                        });
                }
                else {
                    return response.json()
                        .then(j => {
                            this.setError(j)
                            return false;
                        })
                }
            })

    }

    recaptcha(action) {
        const grecaptcha = window.grecaptcha;
        return new Promise((resolve, reject) => {
            grecaptcha.ready(() =>
                grecaptcha.execute(configData.reCaptchaSite, { action: action })
                .then(token => {
                    resolve(token);
                },
                reject)
            );
        })
    }


    //
    // update customer
    forgotpass(info) {
        const me = this

        let args = {
            OrgID: configData.ORGID,
            CustomerID: this.state.customer.id,
            CustomerToken: this.state.cookie
        }

        Object.assign(args, info)
        me.setError(null)

        return this.recaptcha("forgot").then((token) => {
            args.CaptchaV3 = token;
            return StoreUtil.callApi("/customer/password", {
                method: "POST",
                referPolicy: "unsafe-url",  // api needs this to create link in the email sent to customer
                params: args
            })
        })
        .then(response => {
            if (response.ok) {
                return response.json()
                    .then(j => {
                        if (typeof j === 'string') {
                            this.setState({ cookie: j })
                            return j;
                        }
                        else {
                            // we have a customer object (if we sent verify code)
                            return this.updateFromResponse(j)
                        }
                    });
            }
            else if (response.status === 404) {
                this.setError("User not found")
                return false;
            }
            else {
                return response.text()
                    .then(j => {
                        this.setError(j || "Error")
                        return false;
                    })
            }
        })
        .catch(reason => this.setError(reason) );
    }

    //
    // update customer
    updatecustomer(info) {
        const me = this

        let args = {
            OrgID: configData.ORGID,
            CustomerID: this.state.customer.id,
            CustomerToken: this.state.customer.token,
            CartID: this.state.cartid  // move to new customer      
        }

        Object.assign(args, info)

        return StoreUtil.callApi("/customer", {
            method: "POST",
            params: args
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => me.updateFromResponse(j))
                }
                else {
                    return response.text().then(err => {
                        if (response.status === 404)
                            err = "Incorrect Email or Password"
                        me.setError(err)
                        return false
                    })
                }
            }, result => me.setError(result))
    }


    usePoints(p) {
        let args = {
            OrgID: configData.ORGID,
            CustomerID: this.state.customer.id,
            CustomerToken: this.state.customer.token,
            CartID: this.state.cartid,
            Amount: p // points to use  
        }

        // reset error
        if (this.state.error)
            this.setError(null)

        return StoreUtil.callApi("/points", {
            method: "PUT",
            params: args
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => this.openExistingCart())
                }
                else {
                    return response.json()
                        .then(j => this.setError(j))
                }
            })
            .catch(error => this.setError(error))
    }

    //
    // link to an in-store customer
    linkcustomer(info) {
        const me = this

        let args = {
            OrgID: configData.ORGID,
            CustomerID: this.state.customer.id,
            CustomerToken: this.state.customer.token,
            CartID: this.state.cartid  // move to new customer      
        }

        Object.assign(args, info)

        return StoreUtil.callApi("/customer/link", {
            method: "PUT",
            params: args
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => {
                            if (typeof j === 'string')
                                return j;
                            else
                                return me.updateFromResponse(j)
                        })
                }
                else {
                    return response.text().then(err => {
                        if (response.status === 404)
                            err = "Incorrect Email or Password"
                        me.setError(err)
                        return false
                    })
                }
            }, result => me.setError(result))
    }

    //
    // register as a new customer
    register(fn, ln, e, ph, pw) {
        const me = this
        return StoreUtil.callApi("/customer",
            {
                method: "PUT",
                params: {
                    OrgID: configData.ORGID,
                    CustomerID: this.state.customer.id,
                    CustomerToken: this.state.customer.token,
                    CartID: this.state.cartid,  // move to new customer
                    Email: e,
                    Password: pw,
                    Phone: ph,
                    FirstName: fn,
                    LastName: ln
                }
            })
            .then(response => {
                if (response.ok)
                    return response.json().then(j => me.updateFromResponse(j))
                else {
                    return response.text().then((err) => {
                        me.setError(err)
                        return false
                    })
                }
            }, result => me.setError(result))
    }

    loadCustomerOnStoreLoad() {
        //
        // we need customer/token and cart in order to load something
        // that already exists.
        //
        if (this.state.customer.id &&
            this.state.customer.token) {
            //
            // 
            return this.readCustomer()
                .then(() => this.saveCart());
        } else {
            // return it as a promise 
            return new Promise((resolve, reject) => {
                resolve(true)
            })
        }
    }


    componentDidMount() {
        let query = this.useQuery();
        let org = query.get("o")

        // load the correct store based on our url route
        if (configData.Stores && org && (org*1 !== configData.ORGID*1 || !this.state.store)) {
            // find org in stores
            this.loadStoreFromID(org)
        }
        else if (configData.Stores && !org && this.state.store != null) {
            // load the appropriate store from our saved state
            this.loadStore(this.state.store)
        }
        else {
            this.loadCustomerOnStoreLoad()
        }

        //
        // set cookie for password reset if we have one
        const cookie = query.get("cky")
        if (cookie) {
            this.setState({cookie: cookie})
        }

        this.loadCustomHtml()
        
        //
        // watch popstate to render again
        window.addEventListener("popstate",(e) => this.didNavigate(e))
    }

    loadStoreFromID(orgid) {
        for (var i = 0; i < configData.Stores.length; i++) {
            if (configData.Stores[i].ORGID*1 === orgid*1) {
                return this.loadStore(configData.Stores[i])
            }
        }
        return this.globs.queue.notify({
            title: "Error Selecting Store",
            icon: <IoIosWarning size={30} />
        });
    }

    didNavigate(e) {
        let query = this.useQuery();
        let org = query.get("o")
        let screen = query.get("l")

        if (org && org*1 !== configData.ORGID*1 && configData.Stores) {
            // find org in stores
            this.loadStoreFromID(org)
                .then(() => {
                    this.setState({ screen: screen })
                })
        }
        else {
            // force a render. may not need to set anything, really.
            this.setState({ screen: screen })
        }
    }

    //
    // when there are multiple locations, load the
    // given one. 
    loadStore(store) {
        if (configData.ORGID === store.ORGID) {
            // save our store.
            this.setState({
                store: store
            })
            // already loaded, but get customer
            return this.loadCustomerOnStoreLoad()
        }

        // make sure we set the ORGID
        configData.ORGID = store.ORGID;
        // save our store.
        this.setState({
            store: store
        })
        if (store.configUrl) {
            return fetch(store.configUrl)
                .then(response => {
                    return (!response || !response.ok || response.status === 204) ? {} : response.json()
                })
                .then(json => {
                    Object.assign(configData, json)
                    return this.loadCustomerOnStoreLoad()
                })
                .catch(reason => {
                    this.globs.queue.notify({
                        title: "Error Selecting Store",
                        icon: <IoIosWarning size={30} />
                    });
                })
        }
        else {
            return this.loadCustomerOnStoreLoad()
        }
    }

    renderPageSpecific() {
        let query = this.useQuery();
        let screen = query.get("l")
        let org = query.get("o")
        if (!screen) {
            // go to default screen
            if (configData.Stores && configData.Stores.length > 1 && this.state.store === null)
                screen = "choose"
            else
                screen = "browse"
        }

        // if we have not yet loaded the correct store, or we haven't loaded any store,
        // only show choose for now to avoid excess loads via API at the wrong store
        if (configData.Stores && configData.Stores.length > 1 &&
            ((org && org*1 !== configData.ORGID*1) || (this.state.store == null))) {
            screen = "choose"
        }

        if (screen === "browse")
            return (
                <ProductBrowser navonly={false} store={this.state.store} globs={this.globs} />
            )
        else if (screen === "choose")
            return (
                <div className="actionframe">
                    <ChooseStore globs={this.globs} />
                </div>
            )

        const browser = <ProductBrowser globs={this.globs} store={this.state.store} navonly={true} />

        if (screen === "cart")
            return (
                <>
                    {browser}
                    <div className="actionframe">
                        <Cart globs={this.globs} error={this.state.error} username={this.state.customer.user} cart={this.state.cart} edit={true} />
                    </div>
                </>
            )
        else if (screen === "login")
            return (
                <>
                    {browser}
                    <div className="actionframe">
                        <LoginForm globs={this.globs} error={this.state.error} nextscreen={this.state.nextscreen} cart={this.state.cart} edit={true} />
                    </div>
                </>
            )
        else if (screen === "payment")
            return (
                <>
                    {browser}
                    <div className="actionframe">
                        <Suspense fallback={<div>Loading Payment Form...</div>}>
                            <PaymentForm customer={this.state.customer} globs={this.globs} cart={this.state.paycart} submitting={this.state.submitting} />
                        </Suspense>
                    </div>
                </>
            )
        else if (screen === "shipping")
            return (
                <>
                    {browser}
                    <div className="actionframe">
                        <ShippingForm customer={this.state.customer} cart={this.state.cart} globs={this.globs} />
                    </div>
                </>
            )
        else if (screen === "user")
            return (
                <>
                    {browser}
                    <div className="actionframe">
                        <UserForm globs={this.globs} error={this.state.error} customer={this.state.customer} />
                    </div>
                </>
            )
        else if (screen === "forgot")
            return (
                <>
                    {browser}
                    <div className="actionframe">
                        <ForgotPassword globs={this.globs} error={this.state.error} cookie={this.state.cookie} nextscreen={this.state.nextscreen} cart={this.state.cart} edit={true} />
                    </div>
                </>
            )
        else if (screen === "orders")
            return (
                <>
                    {browser}
                    <div className="actionframe">
                        <Orders globs={this.globs} customer={this.state.customer} />
                    </div>
                </>
            )
        else if (screen === "link")
            return (
                <>
                    {browser}
                    <div className="actionframe">
                        <LinkCustomer globs={this.globs} error={this.state.error} nextscreen={this.state.nextscreen} cart={this.state.cart} customer={this.state.customer} />
                    </div>
                </>
            )
        else
            return false
    }

    render() {
        const header = (
            <CartHeader globs={this.globs} error={this.state.error} cart={this.state.cart} user={this.state.customer.user} custommenu={this.state.custommenu} />
        )

        return (
            <div className="store">
                {header}
                <SnackbarQueue
                    messages={this.globs.queue.messages}
                />
                <div className="storecontent">
                    {this.renderPageSpecific()}
                </div>
                <div className="customlinks" dangerouslySetInnerHTML={{ __html: this.state.customlinks }}>
                </div>
                <div className="footer">
                    <span className="poweredby">Powered by Grindless, <RecaptchaBadge /></span>
                </div>
            </div>
        )

    }

}
