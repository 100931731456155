import React, { useState, Suspense } from 'react';
import StoreUtil from './util.js'
import { IoMdHome, IoIosSearch, IoIosMenu, IoMdArrowDropright, IoMdArrowDropdown } from 'react-icons/io'
import { Menu, MenuSurfaceAnchor, MenuItem } from '@rmwc/menu'
import configData from './config.json';
import '@rmwc/menu/styles'

// paymentform is rather heavy, as it includes the CreditCardInput
const ImageZoom = React.lazy(() => import('./zoom.js'))



//
// Render the PRICE
function Price(props) {
    if (props.price > 0) {
        const price = StoreUtil.formatCurrency(props.price)
        const parts = price.split(price[price.length - 3])
        // don't assume our currency symbol is a single digit. Strip out all
        // non numeric digits. Replace numeric party with the localeString
        // version of the numeric part.
        const whole = parts[0].replace(/\d+/,
                parseInt(parts[0].replace(/[^\d]/g, '')).toLocaleString());
        const clsDigits = "pricelen" + whole.length;

        return (
            <span className="price" >
                <span className={clsDigits + " pricewhole"}>{whole}</span><span className="pricefraction">{parts[1]}</span>
            </span>
        )
    }
    // not sure what $0 means...Free? TBD?
    return false;
}

//
// Render the small product view.
function Product(props) {
    const addButton = (props.product.Storable && props.product.Stock <= 0) ?
        <>OUT OF STOCK</> : <button className="smalladd" onClick={props.onAddProduct}>Add</button>;

    let thumb = false;
    if (props.product.Thumb)
        thumb = <img alt={props.product.Name} src={props.product.Thumb} /> 
    else if (props.product.Description && props.product.Description.length > 10)
        thumb = <div className="description"><span dangerouslySetInnerHTML={{ __html: props.product.Description }}></span></div>
    else if (configData.Fallback)
        thumb = <img alt={"Not available"} src={configData.Fallback} /> 

    const title = props.product.Storable ? "in stock:" + props.product.Stock : "";

    return (
        <li className="product" onClick={props.onSelectProduct}>
            {thumb}
            <div className="productSummary">
                <span className={"name" + (thumb !== false ? " narrow" : " wide")} title={title}>{props.product.Name}</span>
                <Price price={props.product.Price} /><br></br>
                {addButton}
            </div>
        </li>
    )
}


//
// Render a single tag
function Tag(props) {
    return (
        <li className="tag" onClick={() => props.onClickTag(props.tag)}>
            <span>#{props.tag}</span>
        </li>
    )
}

//
// Render list of tags
function TagList(props) {
    const [expanded, expand] = useState(1);
    if (!props.tags || props.tags.length === 0) return false;
    const tags = props.tags.map((t) =>
        <Tag key={t} tag={t} onClickTag={() => props.selectTag(t)} />
    )

    const toggle = expanded ? <IoMdArrowDropdown size={"2em"} /> : <IoMdArrowDropright size={"2em"} /> 

    return (
        <div className={expanded ? "taglist collapsible" : "taglist collapsible collapsed"}>
            <h3 onClick={() => expand(!expanded)}><span className="toggle">{toggle}</span>Products By Tag</h3>
            <ul>
                {tags}
            </ul>
        </div>
    )
}

//
// Render the HOME link 
function HomeLink(props) {
    if (configData.Logo) {
        return (
            <div className="homelink">
                <a href={configData.Website} title={configData.Website} >
                    <img src={configData.Logo} alt={configData.Website} />
                </a>
            </div>
        )
    }
    else if (configData.Website) {
        return (
            <div className="homelink">
                <a href={configData.Website} title={configData.Website} >
                    <span>{configData.StoreName}</span>
                </a>
            </div>
        )
    }
}

//
// Render the Search Field
function SearchArea(props) {
    const [search, setSearch] = useState(0);


    const link = (props.lastSearch?.results) ?
        (
            <span className="searchResults" onClick={() => props.onSearch(props.lastSearch.term)}>Search Results: {props.lastSearch.term}</span>
        ) : false;

    return (
        <div className="searcharea">
            <h3>Search</h3>
            <div className="searchBox">
                <input type="text"
                    name="search"
                    autoComplete="off"
                    className="searchField"
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                            props.onSearch(search);
                            e.stopPropagation();
                            e.preventDefault();
                        }
                    }}
                    placeholder={"Search..."}
                /><button
                    onClick={() => props.onSearch(search)}
                    className="searchButton"><IoIosSearch size={"1em"} /></button>
            </div>
            {link}
        </div>
    )
}

//
// Render a single category name
function Category(props) {
    return (
        <li className="category" onClick={() => props.onClickCategory(props.category)}>
            <span>{props.category.Name}</span>
        </li>
    )
}

//
// Render current category hierarchy
function CategoryHierarchy(props) {

    // category
    const hier = [];
    if (typeof props.current === 'object') {
        let subcat = props.current;
        while (subcat) {
            hier.push(subcat)
            subcat = subcat.parent;
        }
        hier.reverse();
    }

    const hierlist = hier.map((cat) =>
        <Category key={cat.ID} category={cat} onClickCategory={props.onClickCategory} />
    )
    return (
        <ul className="categoryhier">
            <li className="category home" onClick={() => props.onClickCategory(0)}>
                <IoMdHome size={"2em"} />
            </li>
            {hierlist}
        </ul>
    )
}

//
// Render list of sub categories
function CategoryList(props) {
    const [expanded, expand] = useState(1);

    const subs = props.categories;
    const cats = subs.map((cat) =>
        <Category key={cat.ID} category={cat} onClickCategory={(cat) => props.onClickCategory(cat)} />
    );

    const toggle = expanded ? <IoMdArrowDropdown size={"2em"} /> : <IoMdArrowDropright size={"2em"} /> 

    return (<div className={expanded ? "categorylist collapsible" : "categorylist collapsible collapsed"}>
        <h3 onClick={() => expand(!expanded)}><span className="toggle">{toggle}</span>Products By Category</h3>
        <CategoryHierarchy current={props.current} onClickCategory={props.onClickCategory} />

        <ul className="catlist">
            {cats}
        </ul>
    </div>
    );
}

//
// Render list of matched products
export function ProductList(props) {


    const showprevnext = props.page > 0 || props.pages > 1;

    const prev = !showprevnext ? false : ((props.page > 0) ?
        <button onClick={() => props.onPage(-1)}>&lt;&lt;</button>
        :
        <button disabled="disabled">&lt;&lt;</button>
    )

    const next = !showprevnext ? false : ((props.page+1 < props.pages) ?
        <button onClick={() => props.onPage(1)}>&gt;&gt;</button>
        :
        <button disabled="disabled">&gt;&gt;</button>
    )

    const pagebuttons = showprevnext ?
        <div className="pagebuttons">{prev}Page {props.page + 1} of {props.pages}{next}</div> :
        false;


    const items = props.products.map((p) =>
        <Product key={p.ID} product={p}
            onSelectProduct={() => props.onSelectProduct(p)}
            onAddProduct={(e) => props.onAddProduct(p,e)}
            globs={props.globs} />
    )

    return (
        <div className="productarea">
            <h1 className="categoryheader">{props.title}</h1>
            <ul className="productlist">
                {items}
            </ul>
            {pagebuttons}
        </div>
    )
}

//
// Render the group of images for a product
function ImageList(props) {
    const [bigIndex, setIndex] = useState(0);

    React.useEffect(() => {
        setIndex(0);
    }, [props.images])

    if (!props.images || props.images.length === 0)
        return false;

    const images = props.images.map((u, i) =>
        <div key={i} className={i === bigIndex ? "imageframe selected" : "imageframe"}
            onClick={() => setIndex(i)}>
            <img alt="Product" src={u} /></div>
    )

    const fallback = <img alt="Product" className="fallback" src={props.images[bigIndex]} />

    return (
        <div className="productimages">
            <div className="selectedImage">
                <Suspense fallback={fallback}>
                    <ImageZoom imageSrc={props.images[bigIndex]}
                            imageAlt="Product" />
                </Suspense>
            </div>
            <div className="carousel">{images}</div>
        </div>
    )
}

//
// Render full product information, for single product page
//
class ProductDetails extends React.Component {
    constructor(props) {
        super(props)
        this.globs = props.globs;
        this.state = {
            quantity: "1",
            quantityopen: false,
            enabled: true
        }
    }

    addItem() {
        if (this.state.enabled) {
            const me = this;
            this.setState({ enabled: false })
            this.globs.addToCart(this.props.product, this.state.quantity, true)
                .then(response => {
                    setTimeout(function () {
                        me.setState({ enabled: true })
                    }, 3000);
                })
        }
    }


    change(e, name) {
        let newstate = {}
        newstate[name] = e.target.value
        this.setState(newstate)
    }

    quantity(evt) {
        this.setState({
            quantity: evt.detail.index + 1,
            quantityopen: false
        });
    }

    renderAddLink() {
        const product = this.props.product;

        if (product.Storable && product.Stock <= 0) {
            return (
                <>OUT OF STOCK</>
                )
        }

        let quantity = [<MenuItem key={1}>1</MenuItem>]
        const max = (product.Storable && product.Stock < 10) ? product.Stock : 10;
        for (let i = 2; i <= max; i++)
            quantity.push(<MenuItem key={i}>{i}</MenuItem>)

        return (
            <div className="productactions">
                <div style={{ width: '80px', 'paddingTop': '3px', display: 'inline-block' }}>

                    <MenuSurfaceAnchor>
                        <Menu open={this.state.quantityopen}
                            anchorCorner={'bottomLeft'}
                            onSelect={evt => this.quantity(evt)}
                            onClose={evt => { this.setState({ quantityopen: false }) }}
                        >
                            {quantity}
                        </Menu>
                        <span className="select button" onClick={evt => this.setState({ quantityopen: !this.state.quantityopen })}>
                            {this.state.quantity} Qty </span>
                    </MenuSurfaceAnchor>
               
            </div>
                <button disabled={!this.state.enabled} onClick={(e) => this.addItem(e)}>Add to Cart</button>
            </div>
        )
    }

    render() {
        const product = this.props.product;

        const addLink = this.renderAddLink()

        const backbutton = this.props.onBack ?
            <span className="backlink" onClick={this.props.onBack}>&lt;&lt; Back to Products</span> : false;

        return (
            <div className="productarea">
                <div className="productdetail">
                    <h1 className="productheader">{product.Name}</h1>
                    {backbutton}
                    <ImageList images={product.Images} />
                    <Price price={product.Price} /> {addLink}
                    <div className="descriptionwrapper">
                        <span className="description" dangerouslySetInnerHTML={{ __html: product.Description }}></span>
                    </div>
                </div>
            </div>
        )
    }
}


//
// Render the full product browser
export default class ProductBrowser extends React.Component {
    constructor(props) {
        super(props)
        this.globs = props.globs;
        this.state = {
            nav: true, // true when showing nav
            categories: [],
            current: 0,
            products: [],
            tags: configData.Tags || [],
            product: null,
            source: 'root',
            page: 0,
            pages: 0,
            search: {
                term: "",
                results: null
            },
            route: ""
        }
    }


    // recursively search for category for given ID
    findCategory(id, list) {
        if (typeof list === 'undefined') {
            return this.findCategory(id, this.state.categories);
        }


        for (const cat of list) {
            // compare as strings
            if (cat.ID + "" === id + "") {
                return cat;
            }
        }

        for (const cat of list) {
            if (cat.Subcategories) {
                const sub = this.findCategory(id, cat.Subcategories);
                if (sub !== null) return sub;
            }
        }
        return null;
    }

    //
    // record the parent category of each category so we 
    // can navigate up the hierarchy easily
    //
    setParentsAndSort(cats, parent) {
        // sort the categories
        cats.sort((a, b) => {
            return a.Name.localeCompare(b.Name, undefined, { sensitivity: 'base', ignorePunctuation: true })
        })
        cats.forEach((cat) => {
            cat.parent = parent
            if (cat.Subcategories)
                this.setParentsAndSort(cat.Subcategories, cat)
        })
    }



    //
    // all details for a single product. All images
    // and full description and attributes
    //
    loadProduct(id) {
        //
        // ignore if we have already loaded this product
        if (this.state.product && this.state.product.ID === id) {
            // just set the state
            this.setState({
                source: "detail"
            });
            this.navigate("s=detail&p=" + encodeURIComponent(id))
            return true;
        }
        const me = this;
        StoreUtil.callApi('/inventory/product/{orgid}/' + id,
            { method: "GET", cache: 'default' })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => {
                            me.setState({
                                source: "detail",
                                product: j
                            });
                            this.navigate("s=detail&p=" + encodeURIComponent(j.ID))
                            return true
                        })
                }
                else {
                    // handle errors here.
                    return response.text()
                }
            })
            .catch(error => console.error(error))
    }


    addProduct(p, e) {
        e.preventDefault()
        e.stopPropagation()
        // we don't re-render when adding. 
        // so in a non-react way, we just alter the
        // element
        var element = e.target;
        element.disabled = true;
        this.globs.addToCart(p, 1, true)
            .then(result => {
                setTimeout(() => {
                    element.disabled = false
                }, 3000)
            })
    }


    clickProduct(p) {
        this.loadProduct(p.ID);
    }

    //
    // load all categories
    loadCategories() {
        const me = this;
        StoreUtil.callApi('/inventory/categories/{orgid}', { method: "GET", cache: 'default' })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => {
                            this.setParentsAndSort(j, null)
                            me.setState({
                                categories: j,
                                finishedLoading: true
                            });
                            me.loadFromQuery()
                        })
                }
                else {
                    // handle errors here.
                    return response.text()
                }
            })
            .catch(error => console.error(error))
    }

    //
    // load list of tags
    loadTags() {
        const me = this;

        //
        // if config has tags, just use those.
        //
        if (typeof configData.Tags !== 'undefined') {
            me.setState({ tags: configData.Tags })
            return;
        }

        StoreUtil.callApi('/inventory/tags/{orgid}',
            { method: "GET", cache: 'default' })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => {
                            me.setState({
                                tags: j,
                                finishedLoading: true
                            })
                        })
                }
                else {
                    // handle errors here. but for tags? doesn't matter
                    return response.text()
                }
            })
            .catch(error => console.error(error))
    }


    //
    // load products from category
    loadProducts(category,page) {
        const me = this;
        // save first page of each category
        if (category.products && page===0) {
            me.setState({
                source: "category",
                products: category.products,
                page: 0,
                pages: category.pages,
                finishedLoading: true
            });
        }
        else {
            return StoreUtil.callApi(
                '/inventory/products/{orgid}/' + category.ID + "/" + page + "/" + configData.PageSize +
                    (configData.OutOfStock ? "?outOfStock=1" : ""),
                { method: "GET", cache: 'default' })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                            .then(j => {
                                if (j.Page === 0) {
                                    category.products = j.Products;  // save first page
                                    category.pages = j.TotalPages
                                }
                                me.setState({
                                    source: "category",
                                    page: j.Page,
                                    pages: j.TotalPages,
                                    products: j.Products,
                                    finishedLoading: true
                                });
                            })
                    }
                })
                .catch(error => console.error(error))
        }
    }



    //
    // load tagged products
    loadTaggedProducts(tag,page) {
        const me = this;
        return StoreUtil.callApi('/inventory/products/tagged/{orgid}/' + encodeURIComponent(tag) + '/' + page + '/' + configData.PageSize,
            { method: "GET", cache: 'default' })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => {
                            me.setState({
                                current: tag,
                                source: "tags",
                                page: j.Page,
                                products: j.Products,
                                pages: j.TotalPages,
                                finishedLoading: true
                            });
                        })
                }
            })
            .catch(error => console.error(error))
    }


    loadFromQuery() {
        const qs = window.location.search
        let query = new URLSearchParams(qs)
        const screen = query.get("l")
        if (this.state.route !== qs) {
            // save so we know whether to render again
            this.setState({
                route: qs,
                back: this.state.route
            })
        }
        if (!screen || screen === "browse") {
            // get page, if set
            const page = query.get("pg") || 0
            // decide what to load
            const source = query.get("s")
            if (!source || source === "root")
                this.selectCategory(0,page);
            else if (source === "search") {
                const q = query.get("q")
                this.searchProducts(q,page)
            }
            else if (source === "tags") {
                const tag = query.get("t")
                this.loadTaggedProducts(tag,page)
            }
            else if (source === "category") {
                const cat = query.get("c")
                this.selectCategory(cat,page)
            }
            else if (source === "detail") {
                const id = query.get("p")
                this.loadProduct(id)
            }
        }
    }

    //
    // path - path that we either need to navigate to, or DID navigate to.
    navigate(path,reload) {
        // hide our nav and scroll to top always.
        window.scrollTo(0, 0);

        var isFull = path.indexOf("&l=browse&")
        if (isFull >= 0) path = path.substring(isFull + "&l=browse&".length);

        var fullpath = StoreUtil.makeRoute("browse", path);

        if (this.props.navonly) {
            // don't show nav on mobile when we come back
            this.hideNav();
            // if we are in "navonly" mode, we need to push this up a level to get rid of
            // cart or login or whatever page is showing. Parent will handle all routing
            this.globs.loadForm("browse&" + path)
            // this will also reload.
            return;
        }

        // save our browse history and set window location.
        if (fullpath !== window.location.search) {
            // almost always will have changed. That's the point. 
            const newpage = window.location.origin + window.location.pathname + fullpath;
            window.history.pushState(null, null, newpage)
        }

        // save so we know whether to render again
        this.setState({
            nav: false,
            route: fullpath,
            back: this.state.route !== fullpath ? this.state.route : this.state.back
        })

        if (reload) {
            this.loadFromQuery();
        }
        
    }

    // start a new search.
    clickSearch(search) {
        this.navigate("s=search&q=" + encodeURIComponent(search),true)
    }

    //
    // load products via search.
    searchProducts(search,page) {
        const me = this;

        if (!search) return; // ignore empty search

        if (!page) page = 0;

        if (this.state.search.term === search &&
            this.state.search.page === page &&
            this.state.search.results !== null) {
            me.setState({
                current: search,
                source: "search",
                products: this.state.search.results,
                page: this.state.page,
                pages: this.state.pages,
                finishedLoading: true
            });
            // make sure we have url set correctly
            this.navigate("s=search&q=" + encodeURIComponent(search) + "&pg=" + page)
            return;
        }

        return StoreUtil.callApi('/inventory/products/search/{orgid}/',
            {
                method: "POST",
                params: {
                    Search: search,
                    PerPage: configData.PageSize,
                    PageNumber: page,
                    OutOfStock: configData.OutOfStock || 0
                },
                cache: 'default'
            })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(j => {
                            me.setState({
                                search: {
                                    term: search,
                                    results: j.Products,
                                    page: j.Page,
                                    pages: j.TotalPages
                                },
                                current: search,
                                source: "search",
                                page: j.Page,
                                products: j.Products,
                                pages: j.TotalPages,
                                finishedLoading: true
                            });
                            this.navigate("s=search&q=" + encodeURIComponent(search) + "&pg=" + page)
                        })
                }
            })
            .catch(error => console.error(error))
    }


    shouldComponentUpdate(nextProps, nextState) {
        // re-render if we changed our display type or store was loaded.
        if (this.props.navonly !== nextProps.navonly ||
            this.props.store !== nextProps.store) {
            return true;
        }

        if (this.state.nav !== nextState.nav)
            return true;

        // checking if products list changed only matters if one of them
        // is not empty
        if (this.state.products !== nextState.products &&
            (this.state.products.length > 0 || nextState.products.length > 0))
            return true;

        if (this.state.categories !== nextState.categories ||
            this.state.current !== nextState.current ||
            this.state.tags !== nextState.tags ||
            this.state.source !== nextState.source ||
            this.state.product !== nextState.product) {
            return true;
        }

        // we should rerender based on the location changing.
        const qs = window.location.search;
        if (this.state.categories.length > 0 &&
            qs !== nextState.route) {
            return true;
        }
            
        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        const qs = window.location.search
        // don't load from query before the categories are loaded
        if (this.state.categories.length > 0 &&
            qs !== this.state.route) {
            this.loadFromQuery();
        }
    }


    componentDidMount() {
        this.loadCategories()
        this.loadTags();
    }

    componentWillUnmount() {
    }


    clickCategory(category) {
        // use the route to automatically load the clicked category
        if (category)
            this.navigate("s=category&c=" + category.ID, true)
        else 
            this.navigate("s=root", true)
    }

    selectCategory(category, page) {
        if (category) {

            if (typeof category !== 'object') 
                category = this.findCategory(category);

            if (!category) return; // categories may not be loaded
            // this only called from loadFromQuery, so finishedLoading should
            // already be false.
            this.setState({
                current: category,
                source: "category",
                pages: 0,
                products: []
            })

            this.loadProducts(category,page || 0)
        }
        else {
            // top level or our START TAG
            if (configData.StartTag)
                this.selectTag(configData.StartTag)
            else
                this.setState({
                    current: 0,
                    source: "root",
                    pages: 0,
                    products: []
                })
        }
    }

    selectTag(tag) {
        this.navigate("s=tags&t=" + encodeURIComponent(tag), true);
    }

    pageChange(dir) {
        var page = this.state.page*1 + dir*1;
        if (page < 0) page = 0;
        // reload by updating our navigation page
        if (this.state.source === "category") 
            this.navigate("s=category&c=" + this.state.current.ID + "&pg=" + page, true);
        else if (this.state.source === "search")
            this.navigate("browse&s=search&q=" + encodeURIComponent(this.state.current) + "&pg=" + page, true);
        else if (this.state.source === "tags")
            this.navigate("browse&s=tags&t=" + encodeURIComponent(this.state.current) + "&pg=" + page, true);
    }

    // toggle nav visibility on small screens.
    toggleNav() {
        this.setState({
            nav: !this.state.nav
        });
    }

    // this will hide nav on small screens only
    hideNav() {
        this.setState({
            nav: false
        });
    }

    renderNavMenu() {
        return (
            <div className={"navmenu"}>
            <IoIosMenu onClick={() => this.toggleNav()} size={"3em"} />
            </div>
        )
    }

    renderNavArea() {
        let current = this.state.current;
        let categories = (this.state.source === 'category')
            ? (this.state.current.Subcategories || [])
            : this.state.categories;
        if (this.state.source === 'tags' || this.state.source === 'search') {
            // tags list, no category to use
            current = 0;
        }

        const storelink = (configData.Stores && this.props.store) ?
            <div className="storename">{this.props.store.Name}
                <span onClick={() => this.props.globs.loadForm("choose")}>change</span>
            </div> :
            false;

        return (
            <div className={"navarea" + (this.state.nav ? "" : " hidenav")}>
                <HomeLink />
                {storelink}
                <SearchArea globs={this.globs}
                    onSearch={(s) => this.clickSearch(s)}
                    lastSearch={this.state.search}
                />
                <CategoryList
                    current={current}
                    onClickCategory={(cat) => this.clickCategory(cat)}
                    categories={categories}
                />
                <TagList selectTag={(t) => this.selectTag(t)} tags={this.state.tags} />
            </div>
        )
    }

    showList() {
        if (this.state.back) {
            this.navigate(this.state.back, true)
        }
    }

    render() {
        const navarea = this.renderNavArea();
        const navmenu = this.renderNavMenu()

        if (this.props.navonly) {
            return (
                <div className={"storebrowser compact"}>
                    {navmenu}
                    {navarea}
                </div>
            )
        }
        else if (this.state.source === 'detail') {
            return (
                <div className={"storebrowser"}>
                    {navmenu}
                    {navarea}
                    <ProductDetails
                        onBack={this.state.back ? () => this.showList() : false}
                        product={this.state.product}
                        globs={this.globs} />
                </div>
            )
        }
        else {
            let title = "";
            switch (this.state.source) {
                default:
                case 'root':
                    title = "Select a Category"
                    break;
                case 'tags':
                    title = '#' + this.state.current;
                    break;
                case 'search':
                    title = 'Results for ' + this.state.current;
                    break;
                case 'category':
                    title = this.state.current.Name;
                    break;
            }


            return (
                <div className={"storebrowser"}>
                    {navmenu}
                    {navarea}
                    <ProductList title={title}
                        products={this.state.products}
                        page={this.state.page}
                        pages={this.state.pages}
                        onSelectProduct={(p) => this.clickProduct(p)}
                        onAddProduct={(p, e) => this.addProduct(p, e)}
                        onPage={(dir) => this.pageChange(dir)}
                        globs={this.globs} />
                </div>
            )

        }

    }

}