import React from 'react';
import configData from './config.json';
import ReactDOM from 'react-dom';
import Store from './store.js'
import './index.css';
import '@rmwc/textfield/styles';
import '@rmwc/select/styles';
import '@rmwc/snackbar/styles';

// ========================================
function renderStore() {
  ReactDOM.render(
    <Store  />,
    document.getElementById('root')
  );
}

// get basic config from config that matches our domain

fetch("/" + window.location.hostname + ".config.json?t=" + (new Date().getTime()))
  .then( response => response.json())
  .then( json => {
        // merge settings into configData, but maybe not done
        Object.assign(configData, json)
        if (configData.configUrl) {
            // use custom config to get more configuration
            // most likely, need to get the API key, which is 
            // only valid a few days at a time. So, this may be a
            // PHP call, or a call to a different server. 
            return fetch(configData.configUrl)
                .then(response => {
                    return (!response || !response.ok || response.status===204) ? {} : response.json()
                })
                .then(json => {
                    Object.assign(configData, json)
                    return configData;
                })
                .catch( rej => configData )
        }
        else {
            return configData;
        }
  })
    .then(d => {
        //
        // append stylesheet and additional libraries.
        if (d && d.StyleSheet) {
            var link = document.createElement("link");
            link.href = d.StyleSheet;
            link.type = "text/css";
            link.rel = "stylesheet";
            // append to head
            document.getElementsByTagName("head")[0].appendChild(link);
        }
        // load reCaptcha from google
        if (d && d.reCaptchaSite) {
            const script = document.createElement('script')
            script.src = "https://www.google.com/recaptcha/api.js?render=" + d.reCaptchaSite
            script.id = "grecaptcha"
            script.defer = true
            document.getElementsByTagName("head")[0].appendChild(script);
        }
        return d;
    })
    .then(() => renderStore())
