//
// show the list of orders or details about a past order.
//
import React from 'react';
import StoreUtil from './util';
import configData from './config.json';
import Cart from './cart.js'
import { IoIosWarning } from 'react-icons/io'

function OpenOrder(props) {
    const order = props.order;
    if (order.Status !== "Open") return false;
    return (
        <li className="order" onClick={props.onClick}>
            Order #{order.OrderID}  {StoreUtil.formatCurrency(order.Total)}<br></br>
            Status: {order.Status} 
        </li>
        )
}

function PastOrder(props) {
    const order = props.order;
    if (order.Status === "Open") return false;
    const paiddate = new Date(order.PaidDate).toLocaleDateString()
    return (
        <li className="order" onClick={props.onClick}>
            Order #{order.OrderID} {paiddate} {StoreUtil.formatCurrency(order.Total)}<br></br>
            Status: {order.Status}
        </li>
    )
}

function AddressLine(props) {
    if (!props.text) return false;
    return <>{props.text}<br></br></>
}


function ShipAddress(props) {
    if (!props.address) {
        if (configData.PickUpInstructions) {
            const instruct = StoreUtil.toHtml(configData.PickUpInstructions)
            return <span className="deliverinfo" dangerouslySetInnerHTML={{ __html: instruct }}></span>
        }
        return false;
    }
    return (
        <>
            <h3>Ship To:</h3>
            <AddressLine text={props.address.RecipientName} />
            <AddressLine text={props.address.BusinessName} />
            <AddressLine text={props.address.Street} />
            <AddressLine text={props.address.StreetLine2} />
            {props.address.City}, {props.address.State} {props.address.PostalCode}
            <AddressLine text={props.address.Country} />
            <AddressLine text={props.address.Phone} />
        </>
        )
}



function ViewOrder(props) {

    return (
        <div className="order">
            <h2>Order #{props.order.OrderID}</h2>
            <h3>Status: {props.order.Status}</h3>
            <Cart edit={false} cart={props.order} />
            <ShipAddress address={props.order.ShipAddress} />
        </div>
        )
}


export default class Orders extends React.Component {
    constructor(props)
    {
        super(props)
        this.globs = props.globs;
        this.state = {
            customer: props.customer,
            orders: [],
            order: null,
            route: StoreUtil.makeRoute("orders")
        }
    }

    componentDidMount() {
        this.loadOrders()
        this.loadFromQuery();
    }

    componentDidUpdate(prevProps, prevState) {
        const qs = window.location.search
        if (qs !== this.state.route) {
            this.loadFromQuery();
        }
    }

    loadFromQuery() {
        const qs = window.location.search
        let query = new URLSearchParams(qs)
        const screen = query.get("l")
        // save so we know whether to render again
        this.setState({
            route: qs
        })
        if (screen === "orders") {
            const orderid = query.get("or")
            if (orderid) {
                this.viewOrder(orderid)
            }
            else {
                this.setState({ order: null });
            }
        }
    }



    //
    // load all categories
    loadOrders() {
        const me = this;
        return StoreUtil.callApi(
            '/carts/' +
            configData.ORGID +
            '/' + this.state.customer.id +
            '/' + this.state.customer.token,
            { method: "GET" }
        ).then(response => {
            if (response.ok)
                return response.json()
            else {
                me.globs.queue.notify({
                    title: "Orders not loaded",
                    icon: <IoIosWarning size={30} />
                });
                return me.state.orders;
            }
        }).then(orders => {
            return me.setState({
                orders: orders
            })
        })
    }

    viewOrder(orderid) {
        this.globs.openCart(orderid)
            .then((order) => {
                if (order) {
                    if (order.Status === 'Open')
                        this.globs.loadForm("cart")
                    else
                        this.setState({
                            order: order
                        })
                }
            })
    }


    clickOrder(order) {
        this.globs.loadForm("orders&or=" + order.CartID)
    }

    allOrders() {
        this.globs.loadForm("orders")
    }


    render() {
        if (this.state.order) {
            return <>
                <span className="backlink" onClick={() => this.allOrders()}>&lt;&lt; All Orders</span>
                <ViewOrder order={this.state.order} />
                </>
        }

        const browsebutton = <button onClick={() => this.globs.loadForm("browse")}>Continue Shopping</button> 

        let openorders = this.state.orders.map((it) =>
            <OpenOrder key={it.OrderID} globs={this.globs} order={it} onClick={() => this.clickOrder(it) } />
            )
        let pastorders = this.state.orders.map((it) =>
            <PastOrder key={it.OrderID} globs={this.globs} order={it} onClick={() => this.clickOrder(it)}  />
        )

        if (!openorders) {
            openorders = <li>You have no open orders</li>
        }
        if (!pastorders) {
            openorders = <li>You have no past orders</li>
        }

        return (
            <div className="orders">
                <h3>Open Orders</h3>
                <ul>
                    {openorders}
                </ul>
                <h3>Past Orders</h3>
                <ul>{pastorders}</ul>
                {browsebutton}
            </div>
        )
    }
}

