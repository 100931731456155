import React from 'react';
import StoreUtil from './util';
import configData from './config.json';
import { TextField } from '@rmwc/textfield'
import { Select } from '@rmwc/select'
import { IoIosWarning } from 'react-icons/io'
import '@rmwc/textfield/styles';



function AddressForm(props) {
    if (props.method==="pickup") return false;
    return (
        <>
            <SavedAddresses 
                list={props.address.addresses}
                onChange={props.onSelectAddress}
            /><br></br>
            <TextField outlined onChange={props.onChange} 
                    name="recipient" 
                    label="Recipient"
                    autoFocus={true}
                    value={props.address.recipient}
                    placeholder={"Recipient Name"} /><br></br>
            <TextField outlined onChange={props.onChange} 
                    name="street" 
                    label="Address Line 1"
                    value={props.address.street}
                    placeholder={"Street Address"} /><br></br>
            <TextField outlined onChange={props.onChange} 
                    label="Address Line 2"
                    name="street2" 
                    value={props.address.street2}
                    placeholder={"Address Line 2"} /><br></br>
            <TextField outlined onChange={props.onChange} 
                    label="City"
                    name="city" 
                    value={props.address.city}
                    placeholder={"City"} /><br></br>
            <TextField outlined onChange={props.onChange} 
                    label="State"
                    name="state" 
                    value={props.address.state}
                    placeholder={"State"} /><br></br>
            <TextField outlined onChange={props.onChange} 
                    label="Postal Code"
                    name="zip" 
                    value={props.address.zip}
                    placeholder={"Zip"} /><br></br>

        </>
    )
}


function ShipMethod(props) {
    return (
        <label key={props.ProductID} className="radio"><input
            key={props.ProductID}
            type="radio" value={props.ProductID}
            name="shipoption"
            checked={props.Selected}
            onChange={(e) => props.onChange(e)}
        /> {props.Name}
        </label>
    );

}


function ShipMethods(props) {
    return props.Options.map((it) => {

        const radio = (<ShipMethod
            key={it.ProductID}
            ProductID={it.ProductID}
            Name={it.Name + ' ' + StoreUtil.formatCurrency(it.Price)}
            Selected={it.ProductID === props.Current}
            onChange={props.onChange}
        />)
        return radio;
    });

}


function SavedAddresses(props) {
    const list = props.list;
    if (!list || list.length === 0) return false;

    let options = list.map((it) => {
        return {
            label: it.Street + ' ' + it.City + ' ' + it.State,
            "value": it.AddressID
        }
    })

    return (
        <Select  
            label="Addresses"
            className="select-address"
            outlined
            options={options}
            onChange={(e) => props.onChange(e)}
        />
    )
}

//
// Select shipping/pickup and save address.
//
export default class ShippingForm extends React.Component {
    constructor(props) {
        super(props)
        this.globs = props.globs;

        const address = (props.cart && props.cart.ShipAddress) || {}

        this.state = {
            method: (props.cart.ShipAddress || configData.PickUp===false) ? "ship" : "pickup",
            addresses: [],
            recipient: address.RecipientName || '',
            biz: address.BusinessName || '',
            street: address.Street || '',
            street2: address.StreetLine2 || '',
            city: address.City || '',
            state: address.State || '',
            zip: address.PostalCode || '',
            country: address.Country || '',
            phone: address.Phone || '',
            submitting: false
        }

    }

    componentDidMount() {
        this.globs.getAddresses()
            .then((list) => {
                this.setState({
                    addresses: list
                })
            })
    }



    loadCart() {
        this.globs.loadForm("cart")
    }

    loadChoice() {
        this.setState({
            options: null
        })
    }

    onChange(e) {
        let newstate = {}
        newstate[e.target.name] = e.target.value
        this.setState ( newstate )
    }

    onSelectAddress(e) {
        const addressid = e.target.value;
        // find address in our list
        for (let i in this.state.addresses) {
            if (this.state.addresses[i].AddressID.toString() === addressid) {
                const a = this.state.addresses[i];
                this.setState({
                    recipient: a.RecipientName,
                    biz: a.BusinessName,
                    street: a.Street,
                    street2: a.StreetLine2,
                    city: a.City,
                    state: a.State,
                    zip: a.PostalCode,
                    country: a.Country,
                    phone: a.Phone
                })
            }
        }
    }

    //
    // submit address
    onContinue() {
        // disable the submit button while we wait
        this.setState({
            submitting: true
        })

        // submit
        let info = {}
        if (this.state.method==='ship') {
            info.Address = {
                RecipientName: this.state.recipient,
                BusinessName: this.state.biz,
                Street: this.state.street,
                StreetLine2: this.state.street2,
                City: this.state.city,
                State: this.state.state,
                Country: this.state.country,
                PostalCode: this.state.zip,
                Phone: this.state.phone
            }
        }
        this.globs.shipAddress(info)
            .then( (options) => {
                if (options) {
                    // no shipping choices, just go to payment
                    if (typeof options === 'string' ||
                        options.length === 1)
                        this.globs.loadForm("payment", true)
                    else if (options.length === 0) {
                        this.globs.queue.notify({
                            title: "No Shipping Options",
                            icon: <IoIosWarning size={30} />
                        });
                        this.setState({
                            method: "pickup",
                            submitting: false,
                            options: null
                        })
                    }
                    else {
                        // choose shipping option
                        this.setState({
                            options: options,
                            submitting: false,
                            shipoption: options[0].ProductID
                        })
                    }
                }
            })
            .catch(error => {
                this.globs.queue.notify({
                    title: "Unexpected Error. Check Network",
                    icon: <IoIosWarning size={30} />
                });
            })
            .finally(() => this.setState({ submitting: false }))
        
    }

    //
    // submit our shipping option, then go to payment.
    onChooseOption() {
        // disable the submit button while we wait
        this.setState({
            submitting: true
        })

        this.globs.shipOption(this.state.shipoption)
            .then((options) => {
                // disable the submit button while we wait
                if (options) {
                    this.globs.loadForm("payment", true)
                }
            })
            .catch(error => {
                this.globs.queue.notify({
                    title: "Unexpected Error. Check Network",
                    icon: <IoIosWarning size={30} />
                });
            })
            .finally(() => this.setState({ submitting: false }))
    }

    onProceedNoDelivery() {
        // must submit as pickup order to set sales tax.
        this.globs.shipAddress({})
            .then((options) => {
                this.globs.loadForm("payment", true)
            });
    }

    haveComplete() {
        return this.state.recipient.length > 1 &&
            this.state.street.length > 3 &&
            this.state.city.length > 1 &&
            this.state.state.length > 1 
    }

    render() {
        const canGo = !this.state.submitting && (this.state.method === "pickup" || this.haveComplete());

        if (this.props.cart.Digital) {
            return (<div>
                Cart does not contain any tangible items for shipping or pick up. Click Continue to proceed to payment.
                <button onClick={() => this.onProceedNoDelivery()}>Continue</button>
            </div>)
        }
        else if (this.state.options) {
            return (
                <div>
                    <span className="backlink" onClick={() => this.loadChoice()}>&lt;&lt; Back</span><br></br>
                    <span>Choose Shipping Method</span>
                    <p>
                        <ShipMethods
                            Current={this.state.shipoption}
                            Options={this.state.options}
                            onChange={(e) => this.onChange(e)}
                        />
                    </p>
                    <button disabled={!canGo}
                        onClick={() => this.onChooseOption()}>Continue</button>
                </div>
            )
        }
        else if (configData.PickUp === false && configData.Shipping === false)
        {
            return (<div>
                No delivery or pick up for this store. Proceed to purchase virtual products or arrange directly with seller.
                <button onClick={() => this.onProceedNoDelivery()}>Continue</button>
            </div>)
        }
        else if (configData.PickUp === false) {
            return (
                <div>
                    <span className="backlink" onClick={() => this.loadCart()}>&lt;&lt; Cart</span><br></br>
                    <span>Enter Delivery Address.</span>
                    <AddressForm method={"ship"}
                        address={this.state}
                        onSelectAddress={(e) => this.onSelectAddress(e)}
                        onChange={(e) => this.onChange(e)}
                    />
                    <button disabled={!canGo}
                        onClick={() => this.onContinue()}>Continue</button>
                </div>
            )
        }
        else if (configData.Shipping === false) {
            // pick up only
            return (<div>
                This order will be made available for Pick Up.
                <button onClick={() => this.onProceedNoDelivery()}>Continue</button>
            </div>)
        }
        else {
            return (
                <div>
                    <span className="backlink" onClick={() => this.loadCart()}>&lt;&lt; Cart</span><br></br>
                    <span>Choose how you would like to receive your items.</span>
                    <p>
                        <label className="radio"><input type="radio"
                            key={"pickup"}
                            value={"pickup"}
                            name="method"
                            checked={this.state.method === "pickup"}
                            onChange={(e) => this.onChange(e)}
                        /> Pick Up in Store</label><br></br>
                        <label className="radio"><input type="radio"
                            key={"pickup"}
                            value={"ship"}
                            name="method"
                            checked={this.state.method === "ship"}
                            onChange={(e) => this.onChange(e)}
                        /> Ship </label><br></br>
                    </p>
                    <AddressForm method={this.state.method}
                        address={this.state}
                        onSelectAddress={(e) => this.onSelectAddress(e)}
                        onChange={(e) => this.onChange(e)}
                    />
                    <button disabled={!canGo}
                        onClick={() => this.onContinue()}>Continue</button>
                </div>
            )
        }
    }
}