import React from 'react';
import StoreUtil from './util.js'
import {TextField} from '@rmwc/textfield'



function VerifyCodeInput(props) {
    return (
        <TextField outlined 
            autoFocus={props.autoFocus}
                onChange={props.onChange} 
                value={props.value||''}
                name="verifycode" 
                autoComplete="one-time-code"
                placeholder={"Verify Code"} />
    )
}


//
// Our form/control for forgot password.
// forgot password file works like this:
//  - submit orgid/username/token
//  - code is sent to email/phone for customer or we get 404
//  - on successful call, prompt for code
//  - submit OrgID/Username/CustomerToken/VerifyCode/Password
//  - get customer information back as result.
//
export default class ForgotPassword extends React.Component {

    constructor(props) {
        super(props)
        this.globs = this.props.globs
        const qs = window.location.search
        const query = new URLSearchParams(qs)
        const code = query.get("vc")
        const user = query.get("u")
        this.state = {
            forgotname: user || '',
            newpass1: "",
            newpass2: "",
            verifycode: code || '',
            pwstrength: -1,
            route: qs,
            submitted: !!user,  // true if we have a code in route
            waiting: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const qs = window.location.search
        if (qs !== this.state.route) {
            const query = new URLSearchParams(qs)
            const code = query.get("vc")
            const user = query.get("u")
            this.setState({
                verifycode: code || '',
                route: qs,
                submitted: !!user,
                forgotname: user || ''
            })
        }
    }

    componentDidMount() {
        // set the submitted state based on if we have a verifycode

    }

    changeInput(e) {
        let newstate = {}
        newstate[e.target.name] = e.target.value
        this.setState ( newstate )
    }

    keyUp(e) {
        if (e.charCode === 13 && this.canSubmit()) {
            if (this.state.submitted) {
                this.clickReset()
            }
            else {
                this.clickForgot()
            }
        }
    }


    verifyForm(cookie) {
        const route = StoreUtil.makeRoute('forgot', "vc=&cky=" + cookie + '&u=' + encodeURIComponent(this.state.forgotname))
        const newpage = window.location.origin + window.location.pathname + route;
        // add to history and update current URL
        window.history.pushState(null, null, newpage)
        // update state
        this.setState({ submitted: true, route: route });
    }

    clickForgot() {
        this.setState({
            waiting: true
        })
        this.globs.forgotpass({
            Username: this.state.forgotname
        })
        .then( (cookie) => {
            if (cookie) this.verifyForm(cookie)
        })
        .finally(() => this.setState({ waiting: false }))
    }

    
    clickReset() {
        this.setState({
            waiting: true
        })
        this.globs.forgotpass(
            {
                Username: this.state.forgotname,
                Password: this.state.newpass1,
                VerifyCode: this.state.verifycode
            })
            .then((ok) => {
                if (ok !== false)
                    this.globs.refresh()
                        .then(() => this.globs.loadForm(this.props.nextscreen || "browse"))
            })
            .finally(() => this.setState({ waiting: false }))
    }


    blurPassword(e) {
        const score = StoreUtil.scorePassword(e.target.value)
        this.setState( {
            pwstrength: score
        })
    }

    focusPassword(e) {
    }    


    canSubmit() {
        if (this.state.submitted) {
            return this.state.newpass1.length > 0 &&
                this.state.newpass2.length > 0 &&
                this.state.newpass1 === this.state.newpass2 &&
                this.state.pwstrength >= 4 &&
                this.state.verifycode.length >= 5;
        }
        else {
            return StoreUtil.validEmail(this.state.forgotname)
        }
    }

    render() {
        let resetmessage = "";
        if (this.state.newpass1.length > 0 &&
            this.state.newpass2.length > 0 &&
            this.state.newpass1 !== this.state.newpass2) {
            resetmessage = "Passwords do not match"
        }
        else if (this.state.pwstrength >= 0 && this.state.pwstrength < 4) {
            resetmessage = "Password too weak. Use 8+ characters, mix case, numbers and symbols"
        }
        else if (this.state.verifycode.length > 0 && this.state.verifycode.length < 5) {
            resetmessage = "Verify code too short"
        }

        const canSubmit = this.canSubmit() && !this.state.waiting;


        if (this.state.submitted) {
            return (
            <div className="storelogin" onKeyPress={(e) => this.keyUp(e)}>
            <fieldset>
            <legend>Forgot Password</legend>
                You should receive a code in email or text message.
                Enter it below with a new password.
            <VerifyCodeInput autoFocus={true}
                    onChange={(e) => this.changeInput(e)} 
                    value={this.state.verifycode} />
            <TextField outlined onChange={(e) => this.changeInput(e)} 
                    onBlur={(e) => this.blurPassword(e)} 
                    onFocus={(e) => this.focusPassword(e)} 
                    name="newpass1" 
                    type="password"
                    autoComplete="new-password"
                    placeholder={"New Password"} />
            <TextField outlined onChange={(e) => this.changeInput(e)} 
                    name="newpass2"
                    type="password" 
                    autoComplete="new-password"
                    placeholder={"Confirm Password"} />
            <span className="errortext">{resetmessage}</span>                    
            <span className="errortext">{this.props.error}</span>            
                <button disabled={!canSubmit} onClick={() => this.clickReset()}>Submit</button>
            </fieldset>
            </div>
            ) ;
        }


        return (
            <div className="storelogin" onKeyPress={(e) => this.keyUp(e)} >
            <fieldset>
            <legend>Forgot Password</legend>
                Enter your Email address and we will send you a code to reset your password.
                <TextField outlined onChange={(e) => this.changeInput(e)} 
                    name="forgotname" 
                    autoFocus={true}
                    placeholder={"Email"} />
                <span className="errortext">{this.props.error}</span>
                <div>
                    <button disabled={!canSubmit} onClick={() => this.clickForgot()}>Submit</button>
                </div>
            </fieldset>
            <div className="flexLinks">
            <span className="clickable" 
                onClick={() => this.globs.loadForm("login:"+(this.props.nextscreen||''))} >
                Log In
            </span>
            </div>         

        </div>
        )
    }

}