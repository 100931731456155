import React from 'react';
import StoreUtil from './util.js'
import {TextField} from '@rmwc/textfield'



function VerifyCodeInput(props) {
    return (
        <TextField outlined label="Verify Code"
                onChange={props.onChange} 
                value={props.value||''}
                name="verifycode" 
                autoComplete="one-time-code"
                placeholder={"Verify Code"} />
    )
}


//
// Our form/control for forgot password.
// forgot password file works like this:
//  - submit orgid/username/token
//  - code is sent to email/phone for customer or we get 404
//  - on successful call, prompt for code
//  - submit OrgID/Username/CustomerToken/VerifyCode/Password
//  - get customer information back as result.
//
export default class LinkCustomer extends React.Component {

    constructor(props) {
        super(props)
        this.globs = this.props.globs
        this.state = {
            firstname: this.props.customer.firstname || '',
            lastname: this.props.customer.lastname || '',
            phone: this.props.customer.phone || '',
            email: this.props.customer.email || '',
            newpass1: "",
            newpass2: "",
            verifycode: "",
            linktoken: "",
            pwstrength: -1,
            submitted: this.props.submitted || false
        }
    }

    changeInput(e) {
        let newstate = {}
        newstate[e.target.name] = e.target.value
        this.setState ( newstate )
    }


    clickSubmit() {
        this.globs.linkcustomer({
            FirstName: this.state.firstname,
            LastName: this.state.lastname,
            Phone: this.state.phone,
            Email: this.state.email,
            SetPassword: true,
            Password: this.state.newpass1,
            OldPassword: this.state.verifycode,
            LinkToken: this.state.linktoken
        })
        .then( (ok) => {
            if (typeof ok === 'string') {
                this.setState({
                    linktoken: ok,
                    submitted: true
                });
            }
            else if (ok) {
                // successfully linked!
                this.globs.loadForm("browse")
            }
        })
    }

    
    clickReset() {
        this.globs.forgotpass({
            Username: this.state.forgotname,
            Password: this.state.newpass1,
            VerifyCode: this.state.verifycode
        })
        .then( () => this.globs.refresh() )
        .then( () => this.globs.loadForm(this.props.nextscreen || "browse") )
    }


    blurPassword(e) {
        const score = StoreUtil.scorePassword(e.target.value)
        this.setState( {
            pwstrength: score
        })
    }

    focusPassword(e) {
        // clear message while typing password
        this.setState( {
            pwstrength: -1
        })
    }    

    render() {
        const newlogin = !this.props.customer.user  || this.props.customer.user === "QUICK";


        if (this.state.submitted) {
            let resetmessage = "";
            if (newlogin)             
            {
                if (this.state.newpass1.length > 0 && 
                    this.state.newpass2.length > 0 &&
                    this.state.newpass1!==this.state.newpass2) {
                    resetmessage = "Passwords do not match"
                }
                else if (this.state.pwstrength >= 0 && this.state.pwstrength < 4) {
                    resetmessage = "Password too weak. Use 8+ characters, mix case, numbers and symbols"
                }
            }
            const canSubmit = this.state.verifycode.length >= 5  &&
                ((resetmessage==="" && this.state.pwstrength >= 4) || !newlogin);

            const pwdsection = !newlogin ? false : [
                (<TextField outlined label="New Password" onChange={(e) => this.changeInput(e)} 
                        onBlur={(e) => this.blurPassword(e)} 
                        onFocus={(e) => this.focusPassword(e)} 
                        name="newpass1" 
                        type="password"
                        autoComplete="new-password"
                        placeholder={"New Password"} />),
                (<TextField outlined label="Confirm Password" onChange={(e) => this.changeInput(e)} 
                        name="newpass2"
                        type="password" 
                        autoComplete="new-password"
                        placeholder={"Confirm Password"} />)
            ]
            

            return (
            <div className="storelogin">
            <fieldset>
            <legend>Link Account</legend>
                You should receive a code in email or text message.
                Enter it below with a new password.
            <VerifyCodeInput 
                    onChange={(e) => this.changeInput(e)} 
                    value={this.state.verifycode} />
            {pwdsection}
            <span className="errortext">{resetmessage}</span>                    
            <span className="errortext">{this.props.error}</span>            
            <button disabled={!canSubmit} onClick={() => this.clickSubmit()}>Submit</button>
            </fieldset>
            </div>
            ) ;
        }

        const canSubmit = StoreUtil.validEmail(this.state.email);

        return (
            <div className="storelogin">
            <fieldset>
            <legend>Link Account</legend>
            Enter customer information to find and link to in-store customer account.
            If you have a membership or loyalty points, this will allow you to use those benefits.
            The phone number must match and name should match. If found, an SMS message will be sent 
            to the number with a validation code.
            <TextField required  outlined onChange={(e) => this.changeInput(e)} 
                    name="firstname" 
                    label="First Name"
                    value={this.state.firstname}
                    autoFocus={true}
                    placeholder={"First Name"} /><br></br>
            <TextField required outlined onChange={(e) => this.changeInput(e)} 
                    name="lastname" 
                    label="Last Name"
                    value={this.state.lastname}
                    placeholder={"Last Name"} /><br></br>
            <TextField required outlined onChange={(e) => this.changeInput(e)} 
                    name="email" 
                    label="Email"
                    value={this.state.email}
                    placeholder={"Email"} /><br></br>
            <TextField required outlined onChange={(e) => this.changeInput(e)} 
                    name="phone" 
                    label="Phone"
                    value={this.state.phone}
                    placeholder={"Phone"} /><br></br>


            <span className="errortext">{this.props.error.toString()}</span>
            <button disabled={!canSubmit} onClick={() => this.clickSubmit()}>Submit</button>
            </fieldset>
            <div className="flexLinks">
            
            </div>         

            </div>
        )
    }

}