

import configData from './config.json';


export class StoreUtil {
    static formatCurrency(amount) {
        if (amount === null || typeof amount === 'undefined')
            return ""
        return configData.DOLLAR + amount.toFixed(2);
    }

    static formatPhone(value) {
        if (!value) return '';
        // remove existing formatting
        value = value.replace(/[ )(-]/g, '')
        if (value.length > 2 && value[0] === '+' && value[1] === '1') {
            // it's US number. drop the +1
            value = value.substring(2);
        }
        if (value.length > 10 || value.indexOf('+') === 0) return value;
        value = ('0000000000' + value).slice(-10);
        return value.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3');
    }

    static validPhone(ph) {
        if (typeof ph !== 'string') return false;
        return /(\+[2-9][\d )(-]{3,20})|((\+1\s?)?((\(\d{3}\))|(\d{3}))\s?-?\s?\d{3}\s?-?\s?\d{4})/.test(ph)
    }

    static validEmail(em) {
        if (typeof em !== 'string') return false;
        if (!em.includes('@')) return false;
        var parts = em.split('@')
        if (parts.length !== 2) return false
        if (parts[0].length === 0) return false;
        if (!parts[1].match(/^[A-Za-z0-9][A-Za-z0-9.-]*\.[A-Za-z0-9]+$/)) return false;
        if (parts[1].includes('..')) return false;

        return true;
    }

    static scorePassword(pw) {
        let score = 0
        if (pw.length >= 8)
        {
            score++;
            if (pw.match(/\d+/) != null) score++
            if (pw.match(/[a-z]/) != null) score++
            if (pw.match(/[A-Z]/) != null) score++
            if (pw.match(/[\\,/,!,@,#,$,%,^,&,*,(,),_,-,=,+]/) != null) score++
        }        
        return score;
    }


    static imageUrl(id) {
        return configData.API_URL.replace('v1','api') + '/graphic/'+configData.ORGID+'/' +
                id + '/icon';
    }


    static loadHTML(endpoint, options) {
        options = Object.assign({
            method: "GET",
            cache: "default",
            referrerPolicy: "no-referrer"
        }, options)
        return fetch(endpoint,
            {
                method: options.method,
                mode: "no-cors",
                cache: options.cache,
                headers: {
                    'Accept': 'text/html'
                },
                referrerPolicy: options.referPolicy
            })
        .then( response => response.text() )
    }


    //
    // call our api and automatically handle calling it again
    // in the case we are calling too rapidly.
    //
    static callApi(endpoint, options) {

        return new Promise((resolve, reject) => {
            //
            // use this technique with a separate promise so
            // we can implement the resubmits when API hits
            // go too fast.
            //
            function submit() {
                return fetch(configData.API_URL + endpoint.replace('{orgid}', configData.ORGID),
                {
                    method: options.method || "POST",
                    mode: "cors",
                    cache: options.cache || 'no-cache',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + configData.API_AUTH
                    },
                    referrerPolicy: options.referPolicy || 'no-referrer',
                    body: (options.params && JSON.stringify(options.params)) || undefined
                })
                .then(response => {
                    if (response.status === 503) {
                        // retry after slight delay
                        setTimeout(submit, 1000);
                    }
                    else {
                        // pass to next 'then' in caller.
                        resolve(response);
                    }
                })
                .catch( reason => reject(reason))
            }

            submit();
        });
    }

    
    static ProcessParagraphs(Markdown) {
        //
        // make sure all "blank" lines are really blank
        Markdown = Markdown.replace(/\r?\n\s+\n/g, '\n\n');
        var paragraphs = Markdown.split(/\r?\n\r?\n/);  // find double \n\n 

        var build = '';

        // go through all except the last paragraph, which
        // will either be empty or doesn't have a blank line after
        // so should not be in a <p>
        for (var i = 0; i < paragraphs.length; i++) {
            var p = paragraphs[i];
            if (/\S/.test(p)) {
                p = StoreUtil.ProcessBlocks(p, i !== paragraphs.length - 1);
                build += p;
            }
        }

        return build;
    }


    /// <summary>
    /// Process line by line to get ordered and unordered lists or blockquotes
    /// </summary>
    /// <param name="lines"></param>
    /// <returns></returns>
    static ProcessBlocks(Markdown, addP) {
        var listTag = null;
        var listlevel = 0;
        var blockquotelevel = 0;
        var addedP = false;
        var tagStack = []

        var lines = Markdown.split(/\r?\n/);  // insert <p></p>
        var results = [];

        //for( var line of lines )
        for (var i = 0; i < lines.length; i++) {
            var line = lines[i];
            var len = line.length;
            var trimmed = line.replace(/^\s+/, ''); // trim space from start
            var spaces = len - trimmed.length;

            // check blockquote
            var trimprefix = trimmed.replace(/^>+/, '')
            var level = trimmed.length - trimprefix.length;
            while (level > blockquotelevel) {
                results.push("<blockquote>");
                blockquotelevel++;
            }
            while (level < blockquotelevel) {
                results.push("</blockquote>");
                blockquotelevel--;
            }



            // remove any more space. if we have a list inside
            // a block quote, well, use the spaces after the >
            var trimprefix2 = trimprefix.replace(/^\s+/, '');
            if (trimprefix2.length !== trimprefix.length)
                spaces += trimprefix.length - trimprefix2.length;
            // check ul
            if (trimprefix2.indexOf("- ") === 0 ||
                trimprefix2.indexOf("+ ") === 0 ||
                trimprefix2.indexOf("* ") === 0) {
                level = spaces / 4 + 1;
                while (level < listlevel) {
                    listTag = tagStack.pop();
                    results.push("</li></" + listTag + ">");
                    listlevel--;
                }
                if (level === listlevel) {
                    // end list item at same level
                    results.push("</li>");
                }
                while (level > listlevel) {
                    tagStack.push("ul");
                    results.push("<ul>");
                    listlevel++;
                }
                results.push("<li>");
                trimprefix2 = trimprefix2.substring(2);
            }
            else if (/^\d+\. /.test(trimprefix2)) {
                level = spaces / 4 + 1;
                while (level < listlevel) {
                    listTag = tagStack.pop();
                    results.push("</li></" + listTag + ">");
                    listlevel--;
                }
                if (level === listlevel) {
                    // end list item at same level
                    results.push("</li>");
                }
                while (level > listlevel) {
                    tagStack.push("ol");
                    results.push("<ol>");
                    listlevel++;
                }
                results.push("<li>");
                var idx = trimprefix2.indexOf(' ');
                trimprefix2 = trimprefix2.substring(idx + 1);
            }
            else if (listlevel > 0) {
                level = spaces / 4;
                while (level < listlevel) {
                    listTag = tagStack.pop();
                    results.push("</li></" + listTag + ">");
                    listlevel--;
                }
            }

            //
            // remove any potential html nonsense
            trimprefix2 = trimprefix2.replace(/</g, "&lt;");
            trimprefix2 = trimprefix2.replace(/>/g, "&gt;");

            trimprefix2 = trimprefix2.replace(/^### ([^\r]*)\r?$/, "<h3>$1</h3>");
            trimprefix2 = trimprefix2.replace(/^## ([^\r]*)\r?$/, "<h2>$1</h2>");
            trimprefix2 = trimprefix2.replace(/^# ([^\r]*)\r?$/, "<h1>$1</h1>");
            trimprefix2 = trimprefix2.replace(/^---$/, "<hr />");


            // add <p> if we have multiple lines and no blockquote or list
            if (trimprefix2.indexOf('<') !== 0 && blockquotelevel === 0 && listlevel === 0 && addP) {
                // leave on own line so we can do heading processing
                results.push("<p>" + trimprefix2);
                addedP = true;
                addP = false;
            }
            else {
                // inside a blockquote. preserve newlines
                results.push(trimprefix2);
            }

        }

        if (addedP) {
            results.push("</p>")
        }

        // end our lists
        while (0 < listlevel) {
            listTag = tagStack.pop();
            results.push("</li></" + listTag + ">");
            listlevel--;
        }
        // end our blockquote
        while (0 < blockquotelevel) {
            results.push("</blockquote>");
            blockquotelevel--;
        }

        return results.join('\n');
    }



    static toHtml(s, disablelinks) {
        var txt = s;
        if (!txt) return txt;
        txt = txt.replace(/<br>/gi, '  '); // just in case it's got old <br>s which we tend to have
        txt = StoreUtil.ProcessParagraphs(txt);

        // only replace & if followed by a space. Otherwise, allow &cent; and similar. 
        txt = txt.replace(/& /g, "&amp; ");
        // ***WARNING*** get's attention class
        txt = txt.replace(/\*{3}([^<>]*?)\*{3}/gi, "<span class='attention'>$1</span>");
        txt = txt.replace(/\*\*([^<>]*?)\*\*/gi, "<strong>$1</strong>");
        txt = txt.replace(/_([^<>]*?)_/gi, "<em>$1</em>");
        txt = txt.replace(/`([^<>]*?)`/gi, "<code>$1</code>");
        txt = txt.replace(/~~([^<>]*?)~~/gi, "<del>$1</del>");
        txt = txt.replace(/ {2}/gi, "<br />");

        // replace escaped symbols
        txt = txt.replace(/\\_/g, "_");
        txt = txt.replace(/\\\*/g, "*");
        txt = txt.replace(/\\`/g, "`");

        // img tags
        txt = txt.replace(/!\[([A-Za-z0-9 ]+)\]\(([/hd][^\)]*)\)/gi,
            "<img src='$2' alt='$1'></img>");

        // links must start with / or # so we cannot link to other sites.
        var regex = /\[([A-Za-z0-9 ]+)\]\(([/#].*)\)/g
        if (!disablelinks) {
            txt = txt.replace(regex, "<a class='alertlink' href='$2'>$1</a>");
        }
        else {
            txt = txt.replace(regex, "$1");
        }
        return txt;
    }

    static escapeHTML(unsafeText) {
        let div = document.createElement('div');
        div.innerText = unsafeText;
        return div.innerHTML;
    }

    //
    // form just the query portion of the route.
    static makeRoute(screen, other) {
        let xtra = ""
        if (typeof other === 'object') {
            for (const p in other) {
                xtra += '&' + p + '=' + encodeURIComponent(other[p])
            }
        }
        else if (typeof other === 'string') {
            xtra = '&' + other
        }
        return "?o="+configData.ORGID+"&l="+screen+xtra
    }


}

export default StoreUtil;