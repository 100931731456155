import React from 'react';
import StoreUtil from './util.js'
import {TextField} from '@rmwc/textfield'
import '@rmwc/textfield/styles';



//
// Our login/register form
//
export default class LoginForm extends React.Component {

    constructor(props) {
        super(props)
        this.globs = this.props.globs
        this.state = {
            username: "",
            password: "",
            regemail: "",
            regname: "",
            regphone: "",
            regpass1: "",
            regpass2: "",
            pwstrength: 6,
            lastclick: "",
            mode: "login",
            properror: this.props.error,
            error: (this.props.error || '').toString()
        }
    }

    componentDidUpdate()
    {
        // update error message.
        if (this.state.properror !== this.props.error)
        {
            this.setState({
                properror: this.props.error,
                error: (this.props.error || '').toString()
            })
        }
    }

    changeInput(e) {
        let newstate = {error: ''}
        newstate[e.target.name] = e.target.value
        this.setState ( newstate )
    }

    blurPhone(e) {
        var ph = e.target.value;
        if (StoreUtil.validPhone(ph)) {
            ph = StoreUtil.formatPhone(ph)
            this.setState({
                regphone: ph
            })
        }
    }

    onKeyPress(e) {
        if (e.key === 'Enter' || e.key === 13) {
            e.preventDefault();
            if (this.state.mode === "register")
                this.clickRegister();
            else
                this.clickLogin();
        }
    }

    canLogin() {
        return StoreUtil.validEmail(this.state.username) &&
            this.state.password.length > 6 &&
            !this.state.waiting;
    }


    clickLogin() {
        if (!this.canLogin()) return;

        this.setState( {
            lastclick: "login",
            waiting: true
        })

        this.globs.recaptcha('login').then((token) => {
            return this.globs.login(this.state.username, this.state.password, token)
        })
        .then( (ok) => {
            if (ok) {
                this.globs.loadForm(this.props.nextscreen || "browse" )
            } 
        })
        .finally(() => this.setState({waiting: false}))
    }


    canRegister() {
        return StoreUtil.validEmail(this.state.regemail)
            && this.state.regpass1.length > 6
            && !this.state.waiting
            && this.state.regpass1 === this.state.regpass2
            && (this.state.regphone.length === 0 || StoreUtil.validPhone(this.state.regphone))
            && this.state.pwstrength >= 4;
    }
    
    clickRegister() {
        // when ENTER key used, we may not have checked password strength
        this.state.score = StoreUtil.scorePassword(this.state.regpass1)
        if (!this.canRegister()) return;

        this.setState( {
            lastclick: "register",
            waiting: true
        })

        this.globs.register(this.state.regname,"", this.state.regemail, this.state.regphone, this.state.regpass1)
        .then( (ok) => {
            if (ok) {
                this.globs.loadForm(this.props.nextscreen ||  "browse" )
            }
        })
        .finally(() => this.setState({ waiting: false }))

    }

    blurPassword(e) {
        const score = StoreUtil.scorePassword(e.target.value)
        this.setState( {
            pwstrength: score
        })
    }

    focusPassword(e) {
        // clear message while typing password
        this.setState( {
            pwstrength: 6
        })
    }

    switchMode(m) {
        this.setState({mode: m})
    }

    render() {
        const canLogin = this.canLogin();

        let registermessage = "";
        if (this.state.regpass1.length > 0 && 
            this.state.regpass2.length > 0 &&
            this.state.regpass1!==this.state.regpass2) {
            registermessage = "Passwords do not match"
        }
        else if (this.state.pwstrength < 4) {
            registermessage = "Password too weak. Use 8+ characters, mix case, numbers and symbols"
        }
        else if (this.state.lastclick === "register" &&
                this.state.error)
        {
            registermessage = this.state.error;   
        }

        let loginmessage = "";
        if (this.state.lastclick === "login" &&
                this.state.error)
        {
            loginmessage = this.state.error;   
        }        


        const canRegister = this.canRegister()
            && !registermessage;

        if (this.state.mode==="register") 
            return (
            <div className="storelogin" onKeyPress={(e) => this.onKeyPress(e)}>
            <fieldset>
            <legend>Register</legend>
            <TextField key={"name"} label="Full Name" outlined onChange={(e) => this.changeInput(e)} name="regname" /><br></br>
            <TextField key={"phone"} type="phone" label="Phone" outlined
                pattern="(\+\d[\d ]{3,20})|(((\(\d{3}\))|(\d{3}))\s?-?\s?\d{3}\s?-?\s?\d{4})"
                onChange={(e) => this.changeInput(e)}
                onBlur={(e) => this.blurPhone(e)}
                name="regphone"
                value={this.state.regphone}
            />
            <br></br>
            <TextField key={"regemail"} label="Email" outlined required onChange={(e) => this.changeInput(e)} name="regemail"  /><br></br>
            <TextField label="Password"  outlined required onBlur={(e) => this.blurPassword(e)} onFocus={(e) => this.focusPassword(e)} 
                    onChange={(e) => this.changeInput(e)} name="regpass1" type="password" 
                    autoComplete="new-password" /><br></br>
            <TextField   label="Re-type Password"  outlined required onChange={(e) => this.changeInput(e)} name="regpass2" type="password" 
                    autoComplete="new-password" /> <br></br>
            <span className="errortext">{registermessage}</span><br></br>
            <button disabled={!canRegister} onClick={() => this.clickRegister()}>Register</button>
            </fieldset>    
            <div className="flexLinks">
            <span className="clickable" 
                onClick={() => this.switchMode("login")} >
                Log In
            </span>            
            </div>
            </div>       
        )
        else 
        return (
            <div className="storelogin">
            <fieldset>
            <legend>Log In</legend>
            <TextField key={"email"} label="Email"  outlined 
                onChange={(e) => this.changeInput(e)} 
                onKeyPress={(e) => this.onKeyPress(e)}
                name="username" 
                placeholder={"E-mail Address"} /><br></br>
            <TextField key={"password"} 
                label="Password" outlined 
                onKeyPress={(e) => this.onKeyPress(e)}
                onChange={(e) => this.changeInput(e)} name="password" 
                type="password" 
                placeholder="password" 
                autoComplete="current-password" /><br></br>
            <span className="errortext">{loginmessage}</span>
            <button disabled={!canLogin} onClick={() => this.clickLogin()}>Log In</button>
            </fieldset>
            <div className="flexLinks">
            <span className="clickable" 
                onClick={() => this.globs.loadForm("forgot:"+(this.props.nextscreen||''))} >
                Forgot Password
            </span>
            <span className="clickable" 
                onClick={() => this.switchMode("register")} >
                Register
            </span>   
            </div>         
            </div>
        )
    }

}